import { Button, Form, Input, notification, Radio } from "antd";
import React, { useState } from "react";
import { CustomInputPassword, ForgotPassword, FormContainer,RadioContainer, SubmitButton,LoginButton } from "./style";
import {Link} from 'react-router-dom';
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { parseJwtToken } from "../../../utils";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";

const SignInForm = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const handleSubmit = async(values)=> {
        const postData = {
            email: values?.email,
            password: values?.password
        }
        try{
            const response= await apiClient.post(`${BASE_URL}/users/token/obtain/`,
            postData
            )
            if(response?.data?.error){
                notification.error({message : response?.data?.error?.message})
            }
            else if (response.data?.is_email_verified){
                localStorage.setItem(`access_token`,response?.data?.access)
                localStorage.setItem(`refresh_token`,response?.data?.refresh)
                const refresh_token = await parseJwtToken(response?.data?.refresh)
                if(refresh_token?.user_type === "Brand"){
                    navigate(`/dashboard`)
                }else{
                    navigate(`/analytics`)
                }
            }
            else{   
                const resendOtp = await apiClient.post(`${BASE_URL}/users/resend-otp/`,
                {
                    email : values?.email
                })
                if(!resendOtp?.data?.error){
                    notification.success({
                        message : resendOtp?.data?.message
                    })
                }
                navigate('/verify-otp',
                {
                    state : {
                        email : values?.email,
                        password : values?.password
                    }
                })             
            }
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
    }

    const handleForgotPassword =() => {
        const fieldValue = form.getFieldsValue();
        console.log("values",fieldValue)
        navigate(`/create-new-password`, {
           state: {
            email : fieldValue.email
           } 
        })
    }

    return(
        <FormContainer>
        <Form
        className="w-100"
        layout="vertical" 
        form={form}
        onFinish = {handleSubmit}
        >
            <Form.Item
                label='Email'
                name="email"

                rules={[
                { required: true, message: 'Email is required' },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
            name="password"
            label="Password"
            rules={[
                {
                required: true,

                message: 'Password is required',
                },
            ]}
            >
                <CustomInputPassword/>
            </Form.Item>
            <ForgotPassword onClick={handleForgotPassword}>Forgot Password?</ForgotPassword>
            {/* <Link to={'/Sign-in/flow'}> */}
            <SubmitButton>
                <LoginButton
                htmlType="submit"
                >
                    <span style={{fontWeight : 700, fontSize : '20px',lineHeight: '24px'}}>Login</span>
                </LoginButton>
            </SubmitButton>
            {/* </Link> */}
            <div style={{display : 'flex', justifyContent : 'center', marginBottom : '25px', fontSize : '16px', color : '#010043'}}>
                <span>Don't have an account?<a><Link to={"/sign-up"} style={{color : '#4E4BEE'}}> Join now</Link></a></span>
            </div>  
            {/* <div style={{display : 'flex', justifyContent: 'center', alignItems : 'center', width : '100%',flexDirection:'column'}}>
                <span style={{color :'#010043', opacity :'0.5',fontSize : '14px',lineHeight : '17px', fontWeight : 400}}>
                Direct integrated with
                </span>
                <img src="https://static.admirian.com/13c3b303-b70d-411f-a6aa-7d7514c1446e_Meta.png"/>
            </div> */}
        </Form>
    </FormContainer>
    )

}

export default SignInForm