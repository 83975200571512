import React, { useEffect, useState } from "react";
import { ActivityCard, ActivityCardDetail, CancelButton, CardContainer, CardDetail, CardHeading, CommentContainer, Container, CopyText, DisputeButton, FeedbackButton, FileDataContainer, GoLiveContainer, GoLiveSubHeading, Heading, LiveContainer, LiveLink, LiveRequestContainer, NoDataText, PhotoContainer, SaveButton, SubHeading, UploadContainer, } from "./style";
import { Alert, Col, Divider, Input, Modal, Popconfirm, Row, Upload, message, notification } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { MehFilled } from '@ant-design/icons';
import { FlexContainer, FlexEndContainer, MainButton } from '../../utils/common';
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const Deliverable = (props) => {
    const { campaignCreatorDeliverable = {}, handleMarkAsCompleted = () => { }, markAsCompletedLoading = false } = props;
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'));

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([])
    const [isFeedbackEnabled, setIsFeedbackEnabled] = useState(false)
    const [campaignData, setCampaignData] = useState({})
    const [deliverableInfo, setDeliverableInfo] = useState({})
    const [uploadFile, setUploadFile] = useState([])
    const [deleteFile, setDeleteFile] = useState([])
    const [comment, setComment] = useState('')
    const [commentLoading, setCommentLoading] = useState(false)
    const [liveUrlLink, setLiveUrlLink] = useState('')


    const fetchCampaignDetails = async (campaignid) => {
        try {
            const response = await apiClient.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${campaignid}/deliverables/`)
            if (response?.data) {
                setCampaignData(response?.data)
            } else {
                setCampaignData({})
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.error || error?.message
            })
        }
    }

    const fetchSubmission = async () => {
        try {
            const response = await apiClient.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props?.campaignid}/campaign-creator-deliverable/${props?.creatorDeliverableId}/get-submission/`)
            if (response?.data) {
                setDeliverableInfo(response?.data)
            } else {
                setDeliverableInfo({})
            }
        } catch (error) {
            notification.error({
                message: error?.message
            })
        }
    }

    const postComment = async () => {
        setCommentLoading(true)
        try {
            const postData = {
                text: comment
            }
            const response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props?.campaignid}/campaign-creator-deliverable/${props?.creatorDeliverableId}/comment/`, postData)
            if (response?.data?.comment_id) {
                setCommentLoading(false)
                setIsFeedbackEnabled(false)
                fetchSubmission()
            } else {
                setCommentLoading(false)
                notification.error({
                    message: response?.data?.message
                })
            }
        } catch (error) {
            setCommentLoading(false)
            notification.error({
                message: error?.message
            })
        }
    }

    useEffect(() => {
        if (deliverableInfo?.submission_items?.length > 0) {
            setFileList(deliverableInfo?.submission_items)
        } else {
            setFileList([])
        }
    }, [deliverableInfo])

    useEffect(() => {
        fetchSubmission()
    }, [props?.campaignid, props?.creatorDeliverableId])

    useEffect(() => {
        if (refresh_token?.user_type !== "Brand") {
            fetchCampaignDetails(props?.campaignid)
        }
    }, [props?.campaignid])


    const UploadSubmission = async () => {
        try {
            const formData = new FormData();
            formData.append('file', uploadFile[0]);
            const response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props?.campaignid}/campaign-creator-deliverable/${props?.creatorDeliverableId}/submission/`,
                formData
            )
            if (response?.data?.submission_url) {
                notification.success({
                    message: 'Uploaded Successfully!',
                });
                setUploadFile([]);
            } else {
                notification.error({
                    message: response?.data?.error || response?.data?.message,
                });
                setUploadFile([]);
            }
            fetchSubmission()
        } catch (error) {
            notification.error({
                message: error?.response?.data?.error || error?.message,
            });
            setUploadFile([]);
        }
    }

    const GoLiveSubmission = async() => {
        try {
            const postData = {
                live_content_url : liveUrlLink
            }
            const response = await apiClient.patch(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props?.campaignid}/campaign-creator/${campaignData?.campaign_creator_id}/campaign-creator-deliverable/${props?.creatorDeliverableId}/go-live/`,
                postData
            )
            if (response?.status === 200) {
                notification.success({
                    message: response?.data?.message,
                });
            } else {
                notification.error({
                    message: response?.data?.error || response?.data?.message,
                });
            }
            fetchCampaignDetails(props?.campaignid)
            fetchSubmission()
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || error?.message,
            });
        }
    }

    const DeleteSubmission = async () => {
        try {
            const formData = new FormData();
            formData.append('file', deleteFile[0]);
            const response = await apiClient.delete(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props?.campaignid}/campaign-creator-deliverable/${props?.creatorDeliverableId}/submission/${deleteFile[0]?.item_id}`)
            if (response?.status === 204) {
                notification.success({
                    message : 'Deleted successfully'
                })
                fetchSubmission()
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.error || error?.message,
            });
            setDeleteFile([]);
        }
    }

    const handleCopy = (liveUrl) => {
        navigator.clipboard.writeText(liveUrl);
        message.success("copied!!")
    }


    useEffect(() => {
        if (uploadFile.length === 1) {
            UploadSubmission();
        }
    }, [uploadFile])

    useEffect(() => {
        if (deleteFile.length === 1) {
            DeleteSubmission();
        }
    }, [deleteFile])


    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if(file?.item_type === "video"){
            window.open(file?.url)
        }else{
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                padding: '0px',
                height: '100%'
            }}
            type="button"
        >
            <img height={'100%'} width={"100%"} src="https://static.admirian.com/218904e1-16ea-487a-84d7-070345bd48df_uploadImage.png" />
        </button>
    );

    const navigate = useNavigate()
    return (
        <Container style={refresh_token?.user_type === "Brand" ? { width: "unset", margin: "unset" } : { width: "100%" }}>
            {
                refresh_token?.user_type !== "Brand" && (
                    <>
                        <div
                            style={{ display: 'flex', minHeight: '64px', alignItems: 'center', cursor: 'pointer', width: 'max-content' }}
                            onClick={() => {
                                navigate(`/campaign-summary-influencer/${props?.campaignid}`)
                            }}
                        >
                            <LeftOutlined style={{ marginRight: 4 }} /> Back
                        </div>
                        <Divider />
                    </>
                )
            }
            {
                (campaignCreatorDeliverable?.submission_status?.toLowerCase() === "in review" && refresh_token?.user_type === "Brand") && (
                    <Alert message="Only after marking all deliverables as completed, you can request the influencer to go live." type="info" showIcon style={{ marginBottom: 8 }} />
                )
            }
            {
                !!(deliverableInfo?.live_url?.length && refresh_token?.user_type === "Brand") && (
                    <LiveContainer>
                        <div className='f-bold'>🥳 Yay! {deliverableInfo?.deliverable_basic_info?.name} content is Live</div>
                        <GoLiveSubHeading>Checkout the live content on the link below</GoLiveSubHeading>
                        <LiveLink><div>{deliverableInfo?.live_url}</div><CopyText onClick={() => handleCopy(deliverableInfo?.live_url)}>Copy</CopyText></LiveLink>
                    </LiveContainer>
                )
            }
            <Row gutter={[16, 20]} style={{ marginTop: refresh_token?.user_type !== "Brand" ? "32px" : 'unset', justifyContent: 'center' }}>
                <Col
                    xl={refresh_token?.user_type === "Brand" ? "" : 16}
                    lg={refresh_token?.user_type === "Brand" ? "" : 16}
                    md={refresh_token?.user_type === "Brand" ? "" : 18}
                    sm={24}
                    xs={24}
                >
                                {
               refresh_token?.user_type !== "Brand" && campaignData?.campaign_creator_status === "Live Requested" && !deliverableInfo?.live_url &&
               <LiveRequestContainer style={{marginTop : '12px'}}>
                    <div className='f-bold'>🥳 Yay! {deliverableInfo?.deliverable_basic_info?.name} content is Approved to Go Live</div>
                    <GoLiveSubHeading>Upload content on the appropriate platform and share the link in the box below</GoLiveSubHeading>
                    <Input style={{width : '100%',height : '56px'}} placeholder="Live Link" value={liveUrlLink} onChange={(e)=>setLiveUrlLink(e.target.value)} /> 
                    <FlexEndContainer style={{marginTop : '8px'}}>
                        <SaveButton onClick={()=>{
                            GoLiveSubmission()
                            // handleChangeDob()
                        }}
                        >
                            Save
                        </SaveButton>
                    </FlexEndContainer>
                </LiveRequestContainer>
            }
            {
               refresh_token?.user_type !== "Brand" && deliverableInfo?.live_url &&
               <LiveContainer style={{marginTop : '12px'}}>
                    <div className='f-bold'>🥳 Yay! {deliverableInfo?.deliverable_basic_info?.name} content is Live</div>
                    <GoLiveSubHeading>Checkout the live content on the link below</GoLiveSubHeading>
                    <LiveLink><div>{deliverableInfo?.live_url}</div><CopyText onClick={() => handleCopy(deliverableInfo?.live_url)}>Copy</CopyText></LiveLink>
                </LiveContainer>
            }
                    {
                        refresh_token?.user_type !== "Brand" && (
                            <CardContainer style={{ marginBottom: '32px' }}>
                                <Row gutter={[12, 56]} style={{ display: 'flex', width: '100%' }}>
                                    <Col span={8} style={{ display: 'flex' }}>
                                        <img
                                            src={campaignData?.campaign_details?.brand_thumbnail}
                                            style={{ height: '49px', width: '49px', borderRadius: '50%' }}
                                        />
                                        <Col>
                                            <CardHeading>
                                                Brand Name
                                            </CardHeading>
                                            <CardDetail>
                                                {campaignData?.campaign_details?.brand_name}
                                            </CardDetail>
                                        </Col>
                                    </Col>
                                    <Col span={7}>
                                        <CardHeading>
                                            Approved Rate
                                        </CardHeading>
                                        <CardDetail>
                                            ₹{campaignData?.working_rate}
                                        </CardDetail>
                                    </Col>
                                    <Col span={7}>
                                        <CardHeading>
                                            Status
                                        </CardHeading>
                                        <CardDetail>
                                            {campaignData?.campaign_creator_status}
                                        </CardDetail>
                                    </Col>
                                </Row>
                            </CardContainer>
                        )
                    }
                    <CardContainer style={{ marginBottom: '32px' }}>
                        <Row gutter={[12, 24]} style={{ display: 'flex', width: '100%' }}>
                            <Col span={7}>
                                <CardHeading>
                                    Deliverable Name
                                </CardHeading>
                                <CardDetail>
                                    {deliverableInfo?.deliverable_basic_info?.name}
                                </CardDetail>
                            </Col>
                            <Col span={7}>
                                <CardHeading>
                                    Type
                                </CardHeading>
                                <CardDetail>
                                    {deliverableInfo?.deliverable_basic_info?.media_type}
                                </CardDetail>
                            </Col>
                            <Col span={7}>
                                <CardHeading>
                                    Duration
                                </CardHeading>
                                <CardDetail>
                                    {deliverableInfo?.deliverable_basic_info?.duration}
                                </CardDetail>
                            </Col>
                        </Row>
                        <Row gutter={[12, 56]} style={{ display: 'flex', width: '100%' }}>
                            <Col span={24}>
                        <CardHeading>
                            Brief
                        </CardHeading>
                        <CardDetail>
                            {deliverableInfo?.deliverable_basic_info?.content_inspiration}
                        </CardDetail>
                        </Col>
                        </Row>
                        <Row gutter={[12, 56]} style={{ display: 'flex', width: '100%' }}>
                            <Col span={24}>
                        <CardHeading>
                            Reference
                        </CardHeading>
                        <CardDetail>
                            {deliverableInfo?.deliverable_basic_info?.reference}
                        </CardDetail>
                        </Col>
                        </Row>
                    </CardContainer>
                    <div style={{ marginTop: '48px', marginBottom: '24px' }}>
                        <Heading>
                            Deliverables
                        </Heading>
                        <SubHeading>
                        {
                            refresh_token?.user_type === "Brand" ? 
                            'Content prepared and submitted by influencer for the deliverable' : 
                            'When you are done preparing your content, upload the file(s) here. You can upload max 3 files. To upload more, remove any previous one'
                        }
                        </SubHeading>
                    </div>
                    <UploadContainer>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            disabled={refresh_token?.user_type === "Brand"}
                            beforeUpload={(file) => {
                                setUploadFile([file]);
                                return false;
                            }}
                            onRemove={(file) => {
                                setDeleteFile([file]);
                                return false;
                            }}
                        >
                            {refresh_token?.user_type !== "Brand" ? fileList.length >= 3 ? null : uploadButton :
                                <FlexContainer>
                                    <div style={{ alignSelf: 'center', textAlign: 'center', width: "100%" }}>
                                        <img src='https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg' width={64} height={64} />
                                        <NoDataText>No Files</NoDataText>
                                    </div>
                                </FlexContainer>
                            }
                        </Upload>
                    </UploadContainer>
                    <FlexEndContainer style={{ alignItems: 'center' }}>
                        {/* <a
                            style={{ color: isFeedbackEnabled ? ' var(--Typo-Disabled, #BDBDDB)' : 'var(--System-Red, #E9000B)', fontSize: '14px', lineHeight: '20px', fontWeight: 500, marginRight: '40px', cursor: isFeedbackEnabled ? 'not-allowed' : 'pointer' }}>
                            Raise dispute
                        </a> */}
                        {
                            ((campaignCreatorDeliverable?.submission_status?.toLowerCase() === "in review" || campaignCreatorDeliverable?.submission_status?.toLowerCase() === "completed") && refresh_token?.user_type === "Brand") && (
                                <FeedbackButton loading={markAsCompletedLoading} style={{ marginRight: 8 }} onClick={() => { handleMarkAsCompleted(); fetchSubmission() }} disabled={campaignCreatorDeliverable?.submission_status?.toLowerCase() === "completed"}>
                                    ✅  Mark as Complete
                                </FeedbackButton>
                            )
                        }
                        <FeedbackButton
                            disabled={isFeedbackEnabled ? true : false}
                            onClick={() => setIsFeedbackEnabled(true)}
                        >
                            {
                                refresh_token?.user_type === "Brand" ? '🖋️ Give feedback' : '🖋️ Add comment'
                            }   
                        </FeedbackButton>
                    </FlexEndContainer>
                    {isFeedbackEnabled &&
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                            <CardHeading style={{ fontSize: '16px', lineHeight: '24px' }}>
                                {refresh_token?.user_type === "Brand" ?
                                    'Write a feedback' : 
                                    'Add a comment'
                                }
                            </CardHeading>
                            <TextArea
                                rows={4}
                                style={{ resize: 'none' }}
                                placeholder={
                                    refresh_token?.user_type === "Brand" ?
                                    "e.g. Thanks for sharing your content! While we love the overall concept, could you possibly adjust the lighting to enhance the product visibility? Looking forward to seeing the updated version!" :
                                    "e.g. For this look, I focused on creating a fresh summer glow using hydrating products. I also included some beachy waves in my hair to complement the overall vibe. Looks good?"
                                }
                                disabled={commentLoading}
                                value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value)
                                }}
                            />
                            <FlexEndContainer style={{ marginTop: '16px' }}>
                                <CancelButton
                                    onClick={() => {
                                        setComment('')
                                        setIsFeedbackEnabled(false)
                                    }}
                                    disabled={commentLoading}
                                >
                                    Cancel
                                </CancelButton>
                                <SaveButton
                                    onClick={() => {
                                        postComment()
                                    }}
                                    disabled={commentLoading}
                                >
                                    Send
                                </SaveButton>
                            </FlexEndContainer>
                        </div>

                    }
                    {deliverableInfo?.comments?.length > 0 ?
                        <>
                            <div style={{ marginTop: '48px', marginBottom: '24px' }}>
                                <Heading>
                                    Recent Feedbacks
                                </Heading>
                                <SubHeading>
                                    View and share your opinion or the feedback here
                                </SubHeading>
                            </div>

                            <Row gutter={[0, 16]} style={{ width: '100%', justifyContent: 'flex-end', marginBottom: '16px' }}>
                                {deliverableInfo?.comments?.map((commentDetails) => {
                                    return (
                                        <ActivityCard style={{ width: refresh_token?.user_type === "Brand" ? !commentDetails?.brand_comment ? '100%' : '90%' : commentDetails?.brand_comment ? '100%' : '90%' }}>
                                            <Col span={24} style={{ paddingLeft: '4px' }}>
                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                                                    <div style={{ display: 'flex', color: '#0F0E1B', fontFamily: 'inter', fontSize: '16px', lineHeight: '24px' }}>
                                                        <PhotoContainer>
                                                            {/* {creatorDetails?.image ? 
                                                        <img
                                                        src={creatorDetails?.image}
                                                        alt="logo"
                                                        style={{
                                                        height: '24px',
                                                        width: '24px',
                                                        borderRadius: '50%',
                                                        }}
                                                        />
                                                        :  */}
                                                            <MehFilled style={{ borderRadius: '50%', fontSize: '24px', color: 'gray' }} />
                                                            {/* } */}
                                                        </PhotoContainer>
                                                        {
                                                            (refresh_token?.user_type === "Brand" && commentDetails?.brand_comment) || 
                                                            (refresh_token?.user_type !== "Brand" && !commentDetails?.brand_comment)
                                                            ? "You"
                                                            : commentDetails?.author_name
                                                        }
                                                         {/* {commentDetails?.author_name} */}
                                                    </div>
                                                    <span style={{ color: '#8988BF', fontFamily: 'inter', fontSize: '12px', fontWeight: 300 }}>
                                                        {commentDetails?.timestamp}
                                                    </span>

                                                </div>
                                                <ActivityCardDetail style={{ 
                                                    color: 
                                                    (refresh_token?.user_type === "Brand" && commentDetails?.brand_comment) || 
                                                    (refresh_token?.user_type !== "Brand" && !commentDetails?.brand_comment) 
                                                    ? 'var(--Typo-Mid-High, #2C2B50)' 
                                                    : 'var(--System-Blue, #1551EA)' 
                                                    }}>
                                                {/* <ActivityCardDetail style={{ color: true ? 'var(--System-Blue, #1551EA)' : 'var(--Typo-Mid-High, #2C2B50)' }}> */}
                                                    {commentDetails?.text}
                                                </ActivityCardDetail>

                                            </Col>
                                        </ActivityCard>
                                    )
                                })
                                }
                            </Row>
                        </>
                        :
                        <div style={{ minHeight: '30px' }} />
                    }
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </Col>
            </Row>
        </Container>
    )
}

export default Deliverable;