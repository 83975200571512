import React, { useEffect, useState } from "react";
import {
  AmountInformationContainer,
  CampaignContentContainer,
  CampaignContentSubHeading,
  CampaignInfoDescription,
  CampaignInfoHeading,
  CampaignObjectiveDescription,
  CampaignObjectiveHeading,
  Container,
  ListContainer,
  ListItemDescription,
  ListItemHeading,
  Heading,
  SubHeading,
  ListImageContaner,
  StatusDiv,
  CampaignDetail,
  CancelRequestContainer,
  CancelButton,
  RequestInformationText,
  RequestSubHeadingText,
  LinkText,
  InfluencerProfile,
  CustomRow,
  RejectOfferButton,
  CampaignButtonContainer,
  CampaignTextContainer,
  CampaignInfoContainer,
  ActivityRowContainer,
  ActivityText,
  ActivitySubText,
  ActivityCustomRow
} from "./style";
import { FlexColContainer, FlexContainer, SpaceBetweenContainer } from "../../utils/common";
import { Col, Divider, Row, Button, notification } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MehFilled } from "@ant-design/icons";
import { ActivityCard, ActivityCardDetail, ActivityHeading, ActivitySubHeading, PhotoContainer } from "../CampaignCreator/style";
import ConfirmModal from "./ConfirmModal";
import { parseJwtToken } from "../../utils";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const CampaignSummaryInfluencer = (props) => {
  const refresh_token = parseJwtToken(localStorage.getItem("refresh_token"));
  const [campaignData, setCampaignData] = useState({});
  const [activityLog, setActivityLog] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const fetchCampaignDetails = async (campaignid) => {
    try {
      const response = await apiClient.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${campaignid}/deliverables/`);
      if (response?.status === 202) {
        notification.info({
          message: response?.data?.message
        });
      } else if (response?.data) {
        setCampaignData(response?.data);
      } else {
        setCampaignData({});
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error || error?.message
      });
    }
  };

  const submitCampaignOffer = async (action) => {
    try {
      let postData = {
        action: action
      };
      const response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props.campaignid}/campaign-creator/${campaignData?.campaign_creator_id}/`, postData);
      if (response?.data) {
        notification.success({
          message: response?.data?.message
        });
        if (action === "cancel") {
          navigate("/campaign");
        } else {
          fetchCampaignDetails(props.campaignid);
        }
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error || error?.message
      });
    }
  };
  const applyCampaign = async () => {
    try {
      let postData = {
        rate: campaignData?.working_rate ?? campaignData?.overall_estimation
      };
      const response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props.campaignid}/broadcast/apply/`, postData);
      if (response?.data) {
        notification.success({
          message: response?.data?.message
        });
        setConfirmModalVisible(false);
        fetchCampaignDetails(props.campaignid);
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error || error?.message
      });
    }
  };

  const fetchActivityLog = async () => {
    try {
      const response = await apiClient.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${props.campaignid}/creator-activity-log/`);
      if (response?.data) {
        setActivityLog(response?.data?.activity);
      } else {
        setActivityLog([]);
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.error || error?.message
      });
    }
  };

  useEffect(() => {
    fetchCampaignDetails(props.campaignid);
  }, [props.campaignid]);

  useEffect(() => {
    if (
      (campaignData?.campaign_details?.campaign_type === "Influencer" && campaignData?.campaign_creator_status !== "Pending") ||
      (campaignData?.campaign_details?.campaign_type === "Broadcast" && campaignData?.campaign_creator_status)
    ) {
      fetchActivityLog();
    }
  }, [campaignData]);

  const navigate = useNavigate();
  return (
    <Container>
      <FlexContainer
        className="cursor-pointer"
        style={{ minHeight: "64px", alignItems: "center" }}
        onClick={() => {
          navigate("/campaign");
        }}>
        <LeftOutlined style={{ marginRight: 4 }} /> Back
      </FlexContainer>
      <Divider />
      {campaignData?.campaign_creator_status === "Accepted" && campaignData?.campaign_details?.campaign_type === "Influencer" && (
        <div>
          <CancelRequestContainer>
            <div>
              <RequestInformationText>Waiting for {campaignData?.campaign_details?.brand_name} to complete payment</RequestInformationText>
              <RequestSubHeadingText>
                You can start working once {campaignData?.campaign_details?.brand_name} completes the full payment
              </RequestSubHeadingText>
            </div>
            <CancelButton
              onClick={() => {
                submitCampaignOffer("cancel");
              }}>
              Cancel request
            </CancelButton>
          </CancelRequestContainer>
        </div>
      )}
      <CustomRow gutter={[16, 20]}>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <div>
            <img src={campaignData?.campaign_details?.campaign_banner ? campaignData?.campaign_details?.campaign_banner : "https://static.admirian.com/0d565fa0-d51b-4cd9-9459-9c8521fc5d69_Banner_placeholder.svg" } className="w-100 object-fit-contain" height={290} />
            {/* <img src={campaignData?.campaign_details?.campaign_banner?.length ? campaignData?.campaign_details?.campaign_banner : 'https://static.admirian.com/0d565fa0-d51b-4cd9-9459-9c8521fc5d69_Banner_placeholder.svg'} width={"100%"} height={290} style={{ objectFit: 'contain' }} /> */}
          </div>
          <CampaignDetail>
            <InfluencerProfile src={campaignData?.campaign_details?.brand_thumbnail} width={120} height={120} />
            <FlexContainer className="w-100">
              <div style={{ paddingRight: 24 }}>
                <CampaignObjectiveHeading>Brand Name</CampaignObjectiveHeading>
                <CampaignObjectiveDescription>{campaignData?.campaign_details?.brand_name}</CampaignObjectiveDescription>
              </div>
              <div>
                <CampaignObjectiveHeading>Campaign Status</CampaignObjectiveHeading>
                <CampaignObjectiveDescription>{campaignData?.campaign_details?.status}</CampaignObjectiveDescription>
              </div>
            </FlexContainer>
          </CampaignDetail>
          <CampaignContentContainer>
            <div>
              <CampaignContentSubHeading>Campaign Name</CampaignContentSubHeading>
              <CampaignInfoHeading>{campaignData?.campaign_details?.campaign_name}</CampaignInfoHeading>
            </div>
            <CampaignInfoDescription>{campaignData?.campaign_details?.brief}</CampaignInfoDescription>
          </CampaignContentContainer>
          <CampaignContentContainer>
            <Row gutter={[0, 20]} className="w-100" style={{ display: "flex", gap: "40px" }}>
              <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <CampaignObjectiveHeading>Campaign Objectives</CampaignObjectiveHeading>
                <CampaignObjectiveDescription>{campaignData?.campaign_details?.objective}</CampaignObjectiveDescription>
              </Col>
              <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <CampaignObjectiveHeading>Category</CampaignObjectiveHeading>
                <CampaignObjectiveDescription>{campaignData?.campaign_details?.category}</CampaignObjectiveDescription>
              </Col>
            </Row>
          </CampaignContentContainer>
          <FlexColContainer style={{ margin: "24px 0", gap: "8px" }}>
            <Heading>All Deliverables</Heading>
            <SubHeading>View the deliverables for this campaign</SubHeading>
          </FlexColContainer>
          <ListContainer>
            {campaignData?.deliverables?.map((deliverableData, index) => {
              return (
                <Row
                  key={index}
                  onClick={() => {
                    if (["in progress", "delivered", "live requested", "live"]?.includes(campaignData?.campaign_creator_status?.toLowerCase())) {
                      navigate(`/deliverable/${props.campaignid}/${deliverableData?.campaign_creator_deliverable_id}`);
                    } else {
                      navigate(`/deliverable-details/${deliverableData?.campaign_creator_deliverable_id}/${props.campaignid}`);
                    }
                  }}
                  gutter={[10]}
                  className="w-100 cursor-pointer">
                  <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                    <CampaignTextContainer>
                      <ListImageContaner>
                        <img
                          src={
                            deliverableData?.deliverable_type?.toLowerCase()?.includes("instagram")
                              ? "https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg"
                              : "https://static.admirian.com/d1df6ebc-69fe-47f1-96d1-b82b212c4482_Youtube.svg"
                          }
                        />
                      </ListImageContaner>
                      <CampaignInfoContainer>
                        <ListItemHeading>Name</ListItemHeading>
                        <ListItemDescription style={{ color: "#1551EA" }}>{deliverableData?.deliverable_name}</ListItemDescription>
                      </CampaignInfoContainer>
                    </CampaignTextContainer>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                    <CampaignInfoContainer>
                      <ListItemHeading>Media Type</ListItemHeading>
                      <ListItemDescription>{deliverableData?.deliverable_type}</ListItemDescription>
                    </CampaignInfoContainer>
                  </Col>
                  <Col xl={4} lg={4} md={12} sm={12} xs={24}>
                    <CampaignInfoContainer>
                      <ListItemHeading>Budget</ListItemHeading>
                      <ListItemDescription>{`₹${deliverableData?.min_budget} - ₹${deliverableData?.max_budget}`}</ListItemDescription>
                    </CampaignInfoContainer>
                  </Col>
                  {deliverableData?.submission_status && (
                    <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                      <CampaignButtonContainer>
                        <StatusDiv status={deliverableData?.submission_status}>
                          {deliverableData?.submission_status.toLowerCase() === "preparing content"
                            ? "✍️ "
                            : deliverableData?.submission_status.toLowerCase() === "in review"
                            ? "⏳ "
                            : deliverableData?.submission_status.toLowerCase() === "completed"
                            ? "✅ "
                            : ""}
                          {deliverableData?.submission_status}
                        </StatusDiv>
                      </CampaignButtonContainer>
                    </Col>
                  )}
                  {index < campaignData?.deliverables?.length - 1 && <Divider style={{ margin: "24px 0" }} />}
                </Row>
              );
            })}
          </ListContainer>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          {campaignData?.campaign_details?.campaign_type === "Influencer" ? (
            <AmountInformationContainer>
              {campaignData?.campaign_creator_status === "Pending" ? (
                <>
                  <div>
                    <Heading style={{ marginBottom: "8px" }}>Overall Earnings</Heading>
                    <SubHeading>Includes all deliverables </SubHeading>
                  </div>
                  <FlexColContainer style={{ alignItems: "center" }}>
                    <Heading style={{ fontSize: "32px" }}>₹{campaignData?.working_rate}</Heading>
                    <SubHeading>Includes all deliverables </SubHeading>
                  </FlexColContainer>
                  <div>
                    <Button
                      onClick={() => {
                        submitCampaignOffer("accept");
                      }}
                      size="large"
                      shape="round"
                      type="primary"
                      style={{ marginBottom: "16px" }}>
                      Accept Offer
                    </Button>
                    <FlexContainer className="w-100" style={{ justifyContent: "center" }}>
                      <RejectOfferButton
                        onClick={() => {
                          submitCampaignOffer("reject");
                        }}>
                        Reject Offer
                      </RejectOfferButton>
                    </FlexContainer>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <ActivityHeading>Activity</ActivityHeading>
                    <ActivitySubHeading>Find all the brand and campaign activity updates here</ActivitySubHeading>
                  </div>
                  <ActivityRowContainer gutter={[0, 16]}>
                    {activityLog?.map((activities, index) => {
                      return (
                        <Col key={index} xl={24} lg={24} md={24} sm={24} xs={24}>
                          <ActivityCard>
                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                              <PhotoContainer>
                                {activities?.author_thumbnail ? (
                                  <img
                                    src={activities?.author_thumbnail}
                                    alt="logo"
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      borderRadius: "50%"
                                    }}
                                  />
                                ) : (
                                  <MehFilled style={{ borderRadius: "50%", fontSize: "24px", color: "gray" }} />
                                )}
                              </PhotoContainer>
                            </Col>
                            <Col xl={21} lg={21} md={21} sm={21} xs={21} style={{ paddingLeft: "4px" }}>
                              <SpaceBetweenContainer className="w-100" style={{ alignItems: "flex-start", marginBottom: "12px" }}>
                                <ActivityText>{activities?.author_name}</ActivityText>
                                <ActivitySubText>{activities?.timestamp}</ActivitySubText>
                              </SpaceBetweenContainer>
                              <ActivityCardDetail>{activities?.message}</ActivityCardDetail>
                            </Col>
                          </ActivityCard>
                        </Col>
                      );
                    })}
                  </ActivityRowContainer>
                </>
              )}
            </AmountInformationContainer>
          ) : (
            <AmountInformationContainer>
              {campaignData?.campaign_creator_status ? (
                <>
                  <div>
                    <ActivityHeading>Activity</ActivityHeading>
                    <ActivitySubHeading>Find all the brand and campaign activity updates here</ActivitySubHeading>
                  </div>
                  <ActivityCustomRow gutter={[0, 16]}>
                    {activityLog?.map((activities, index) => {
                      return (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                          <ActivityCard>
                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                              <PhotoContainer>
                                {activities?.author_thumbnail ? (
                                  <img
                                    src={activities?.author_thumbnail}
                                    alt="logo"
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      borderRadius: "50%"
                                    }}
                                  />
                                ) : (
                                  <MehFilled style={{ borderRadius: "50%", fontSize: "24px", color: "gray" }} />
                                )}
                              </PhotoContainer>
                            </Col>
                            <Col xl={21} lg={21} md={21} sm={21} xs={21} style={{ paddingLeft: "4px" }}>
                              <SpaceBetweenContainer className="w-100" style={{ alignItems: "flex-start", marginBottom: "12px" }}>
                                <ActivityText>{activities?.author_name}</ActivityText>
                                <ActivitySubText>{activities?.timestamp}</ActivitySubText>
                              </SpaceBetweenContainer>
                              <ActivityCardDetail>{activities?.message}</ActivityCardDetail>
                            </Col>
                          </ActivityCard>
                        </Col>
                      );
                    })}
                  </ActivityCustomRow>
                </>
              ) : campaignData?.match || (!campaignData?.match && !!campaignData?.connect_platform) ? (
                <>
                  <div>
                    <Heading style={{ marginBottom: "8px" }}>Overall Earnings</Heading>
                    <SubHeading>Includes all deliverables </SubHeading>
                  </div>
                  <FlexColContainer style={{ alignItems: "center" }}>
                    {/* <Heading style={{fontSize : '32px'}}>{campaignData?.match ? `₹${campaignData?.overall_estimation}` : '-'}</Heading> */}
                    <Heading style={{ fontSize: "32px" }}> ₹{campaignData?.overall_estimation}</Heading>
                    <SubHeading>Includes all deliverables </SubHeading>
                  </FlexColContainer>
                  <div>
                    <Button
                      onClick={() => {
                        if (campaignData?.match) {
                          setConfirmModalVisible(true);
                        } else {
                          navigate("/connect-accounts");
                        }
                      }}
                      size="large"
                      shape="round"
                      type="primary"
                      style={{ marginBottom: "16px", height: "max-content" }}>
                      {campaignData?.match ? "Apply to Campaign" : `Check Eligibility`}
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </AmountInformationContainer>
          )}
        </Col>
      </CustomRow>
      {confirmModalVisible && <ConfirmModal isVisible={confirmModalVisible} setIsVisible={setConfirmModalVisible} applyCampaign={applyCampaign} />}
    </Container>
  );
};

export default CampaignSummaryInfluencer;
