import { Col, Divider, Row, notification } from "antd";
import { icons } from "antd/es/image/PreviewGroup";
import React from "react";
import { CreatorBioContainer, CustomButton, InflencerCountInformation, InfluencerTextInformation, LikeButton, ProfileBio, ProfileImg, ProfileName, ProfilePicContainer, SaveButton } from "./style";
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { FlexContainer, FlexEndContainer } from "../../utils/common";
import media from "../../utils/UIElements/media";
import { isMobile } from "../../utils/constants/inedx";
const CreatorBio = ({ creatorDetails, bio, likedUserUuid, getLikedUseIdList, getCategoryCount, drawerView, creatorProfileDetails, creatorProfileDetailsYt, hideConnectionButton }) => {

    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const handleAddLike = async (creator_uuid) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creator/${creator_uuid}/`)
            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message
                })
                getCategoryCount()
                getLikedUseIdList()
            }
            else {
                notification.error({
                    message: response?.data?.message
                })
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    const handleRemoveLike = async (creator_uuid) => {
        try {
            const response = await apiClient.delete(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creator/${creator_uuid}/`)
            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message
                })
                getCategoryCount()
                getLikedUseIdList()
            }
            else {
                notification.error({
                    message: response?.data?.message
                })
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.error?.message })
        }
    }

    const handleConnect = async (creator_uuid) => {
        try {
            const response = await apiClient.put(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/connects/creator/${creator_uuid}/`)
            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message
                })
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.error?.message })
        }
    }

    return (
        <CreatorBioContainer style={{ marginTop: !drawerView && "20px" }}>
            <Row gutter={[16, 20]} className="w-100">
                <Col xl={5} lg={5} md={12} sm={24} xs={24}>
                    <ProfilePicContainer>
                        <ProfileImg
                            src={creatorProfileDetails?.profile_details?.profile_pic_url ? creatorProfileDetails?.profile_details?.profile_pic_url : creatorProfileDetailsYt?.profile_details?.thumbnails?.high ? creatorProfileDetailsYt?.profile_details?.thumbnails?.high : creatorDetails?.image ? creatorDetails?.image : ''}
                            alt="Profile Pic"
                            style={{
                                borderRadius: creatorProfileDetails?.profile_details?.profile_pic_url || creatorProfileDetailsYt?.profile_details?.thumbnails?.high || creatorDetails?.image ? '50%' : '0px',
                            }}
                        />
                    </ProfilePicContainer>

                </Col>
                <Col xl={14} lg={14} md={12} sm={24} xs={24}>
                    <ProfileName>
                        {creatorDetails?.name}
                    </ProfileName>
                    {(creatorProfileDetails?.profile_details?.bio || creatorProfileDetailsYt?.profile_details?.channel_description) &&
                        <ProfileBio>
                            <span className="f-bold" style={{ color: '#01004380', display: 'block' }}>
                                Bio
                            </span>
                            {creatorProfileDetails?.profile_details?.bio ? creatorProfileDetails?.profile_details?.bio : creatorProfileDetailsYt?.profile_details?.channel_description ? creatorProfileDetailsYt?.profile_details?.channel_description : ''}
                        </ProfileBio>
                    }
                    <FlexContainer style={{ marginBottom: 16 }}>
                        {
                            creatorDetails?.categories && creatorDetails?.categories?.map((category, index) => {
                                return (
                                    <CustomButton key={index}>
                                        {category}
                                    </CustomButton>
                                )
                            })
                        }
                    </FlexContainer>
                    <Row gutter={[16, 20]}>
                        {Object.keys(creatorProfileDetails).length > 0 &&
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <FlexContainer>
                                    <img src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg" alt="instagram" height={32} width={32} style={{ marginRight: 8 }} />
                                    <div style={{ marginRight: 30 }}>
                                        <InflencerCountInformation>{creatorProfileDetails?.profile_details?.followers ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Followers</InfluencerTextInformation>
                                    </div>
                                    <div style={{ marginRight: 30 }}>
                                        <InflencerCountInformation>{creatorProfileDetails?.profile_details?.reach ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Reach</InfluencerTextInformation>
                                    </div>
                                    <div style={{ marginRight: 30 }}>
                                        <InflencerCountInformation>{creatorProfileDetails?.profile_details?.media_count ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Media</InfluencerTextInformation>
                                    </div>
                                </FlexContainer>
                            </Col>
                        }
                        {Object.keys(creatorProfileDetailsYt).length > 0 &&
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <FlexContainer>
                                    {
                                        window.innerWidth > 767 && (
                                            <Divider type="vertical" style={{ height: 32, marginRight: 32 }} />
                                        )
                                    }
                                    <img src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg" width={32} height={32} alt="youtube" style={{ marginRight: 12 }} />
                                    <div style={{ marginRight: 30 }}>
                                        <InflencerCountInformation>{creatorProfileDetailsYt?.profile_details?.subscriber_count ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Subscribers</InfluencerTextInformation>
                                    </div>
                                    <div style={{ marginRight: 30 }}>
                                        <InflencerCountInformation>{creatorProfileDetailsYt?.profile_details?.view_count ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Views</InfluencerTextInformation>
                                    </div>
                                    <div>
                                        <InflencerCountInformation>{creatorProfileDetailsYt?.profile_details?.video_count ?? '-'}</InflencerCountInformation>
                                        <InfluencerTextInformation>Videos</InfluencerTextInformation>
                                    </div>
                                </FlexContainer>
                            </Col>
                        }
                    </Row>
                </Col>
                {hideConnectionButton ? <Col xl={5} lg={5} md={12} sm={24} xs={24} />
                    : drawerView ?
                        <Col xl={5} lg={5} md={12} sm={24} xs={24}>
                            <FlexContainer style={{justifyContent: `${isMobile ? 'center' : 'flex-end'}`}}>
                            <LikeButton
                                icon={likedUserUuid?.includes(creatorDetails?.creator_uuid) ? <HeartFilled style={{ color: 'white' }} /> : <HeartOutlined />}
                                onClick={() => {
                                    likedUserUuid?.includes(creatorDetails?.creator_uuid) ?
                                        handleRemoveLike(creatorDetails?.creator_uuid)
                                        :
                                        handleAddLike(creatorDetails?.creator_uuid)
                                }}
                            >
                                Save
                            </LikeButton>
                            <SaveButton
                                onClick={() => handleConnect(creatorDetails?.creator_uuid)}
                            >
                                Connect
                            </SaveButton>
                            </FlexContainer>
                        </Col>
                        :
                        <Col xl={5} lg={5} md={12} sm={24} xs={24} />
                }
            </Row>
        </CreatorBioContainer>
    )
}

export default CreatorBio