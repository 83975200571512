import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { MinimalUserCardContainer, MinimalImageContainer} from "./style";

const MinimalUserDetails = ({ influencerData }) => {
    const { 
        ig_account_name = "", 
        profile_picture_url = "", 
        followers = "0", 
        media_count = "0", 
        biography = "" 
    } = influencerData;

    return (
        <div 
            style={{ 
                width: "100%", 
                display: "flex", 
                justifyContent: "center", 
                padding: "0 20px" // Marginal space on the sides
            }}
        >
            <MinimalUserCardContainer>
                <MinimalImageContainer 
                    backGroundImageUrl = {profile_picture_url}
                />

                <div style={{ 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    width: "80%", 
                }}>
                    <span style={{ fontWeight: 600, fontSize: "14px", color: "#010043", alignItems: "center",  }}>
                        {ig_account_name}
                    </span>
                    {/* <img src="https://static.admirian.com/8fc5caac-833f-4dd7-a6d2-c9d325ca15dc_socialMedia.svg" /> */}
                </div>

                {biography.trim() !== "" && (
                    <div style={{ 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        width: "80%", 
                        marginBotton: "10px"
                    }}>
                        <span style={{ fontWeight: 500, fontSize: "12px", color: "#010043"  }}>
                            {biography}
                        </span>
                    </div>
                )}
                
                <div style={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    width: "80%", 
                    marginTop: "10px"
                }}>
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: 600, fontSize: "15px", color: "#010043" }}>
                            {followers}
                        </span>
                        <div style={{ fontSize: "12px", color: "#01004399" }}>Followers</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: 600, fontSize: "15px", color: "#010043" }}>
                            {media_count}
                        </span>
                        <div style={{ fontSize: "12px", color: "#01004399" }}>Media</div>
                    </div>
                </div> 
            </MinimalUserCardContainer>
        </div>
    );
};

export default MinimalUserDetails;
