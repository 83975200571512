  import React, { useState, useEffect } from "react";
  import { Modal, Input, Button, Spin, Alert, Space, notification } from "antd";
  import { useDispatch, useSelector } from "react-redux";
  import { openModal, closeModal, fetchInfluencerDetails, inviteInfluencer, resetInviteState } from "../../store/inviteSlice";
  import MinimalUserDetails from '../Dashboard/UserDetails/MinimalUserDetails';

  const InviteInfluencerModal = () => {
    const [igHandle, setIgHandle] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [creatorEmail, setCreatorEmail] = useState("");
    const [step, setStep] = useState(1);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [showIgInput, setShowIgInput] = useState(true); // Control visibility of IG handle input

    const dispatch = useDispatch();
    const { isOpen, influencerDetails, loading, error, inviteLoading, inviteSuccess } = useSelector((state) => state.invite);

    // Reset state when the modal opens
    useEffect(() => {
      if (isOpen) {
        setStep(1);
        setIgHandle("");
        setCreatorName("");
        setCreatorEmail("");
        setIsEmailRequired(false);
        setShowIgInput(true); // Show IG handle input initially
        dispatch(resetInviteState()); // Clear previous API responses
      }
    }, [isOpen, dispatch]);

    // Handle API call to fetch influencer details
    const handleFetchDetails = async () => {
      const result = await dispatch(fetchInfluencerDetails(igHandle));
      if (result.payload) {
        const { is_email_required } = result.payload;
        setIsEmailRequired(is_email_required);
        setShowIgInput(false); // Hide IG input once influencer details are fetched
      }
    };

    const handleInvite = () => {
      dispatch(inviteInfluencer({ igHandle, name: creatorName, email: creatorEmail }));
    };

    const handleBack = () => {
      setStep(1);
      setShowIgInput(true);
      setIgHandle("");
      dispatch(resetInviteState());
    };

    const handleClose = () => {
      dispatch(closeModal());
    };

    // Show success notification & close modal when invite is sent
    useEffect(() => {
      if (inviteSuccess) {
        notification.success({
          message: "Invite Sent!",
          description: "The influencer has been successfully invited.",
        });
        dispatch(closeModal()); // Close the modal
      }
    }, [inviteSuccess, dispatch]);

    return (
      <Modal 
        open={isOpen} 
        onCancel={handleClose} 
        footer={null} 
        title={<div style={{ textAlign: "center", width: "100%" }}>Invite Influencer</div>}
      >
        {step === 1 ? (
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {/* Show IG handle input only if influencer details are not fetched */}
            {showIgInput && (
              <>
                <Input
                  placeholder="Enter Instagram Handle"
                  value={igHandle}
                  onChange={(e) => setIgHandle(e.target.value)}
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={handleFetchDetails} disabled={!igHandle}>
                    Continue
                  </Button>
                </div>
              </>
            )}
            {loading && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "120px" }}>
                <img src="https://static.admirian.com/admirian_fetch_insights.png" alt="Loading..." style={{ width: "100px", height: "73px" }} />
                <div style={{ marginTop: "10px", fontSize: "16px", textAlign: "center" }}>Fetching Info...</div>
              </div>
            )}
            {error && <Alert type="error" message={error} />}

            {/* Show influencer details once fetched */}
            {influencerDetails && (
              <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <MinimalUserDetails influencerData={influencerDetails?.ig_profile_info} />
                </div>
                
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "10px" }}>
                  <Button onClick={handleBack}>Back</Button>
                  {!isEmailRequired ? (
                    <Button type="primary" onClick={() => dispatch(inviteInfluencer({ igHandle }))}>
                      Invite
                    </Button>
                  ) : (
                    <Button type="primary" onClick={() => setStep(2)}>
                      Next
                    </Button>
                  )}
                </div>
              </>
            )}
          </Space>
        ) : (
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Input
              placeholder="Enter Creator Name"
              value={creatorName}
              onChange={(e) => setCreatorName(e.target.value)}
            />
            <Input
              placeholder="Enter Creator Email"
              value={creatorEmail}
              onChange={(e) => setCreatorEmail(e.target.value)}
            />
            {inviteLoading && <Spin />}
            <div style={{ textAlign: "center" }}>
              <Button type="primary" onClick={handleInvite} disabled={!creatorName || !creatorEmail}>
                Send Invite
              </Button>
            </div>
          </Space>
        )}
      </Modal>
    );
  };

  export default InviteInfluencerModal;
