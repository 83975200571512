import React, { useEffect, useState } from "react";
import { AdditionalDeliverableContainer, FormContainerRate, Label, NavigateButton, RateModalContainer } from "./style";
import { Cascader, Col, Divider, Form, Input, Modal, Row, Select, notification } from "antd";
import { Option } from "antd/es/mentions";
import { FlexEndContainer } from "../../utils/common";
import { CancelButton, SaveButton } from "../Profile/style";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";


const RateCardModalCreator = ({visible,setVisible,getRateCard,insagramRateCard,youtubeRateCard,rateCardData}) => {
    const [form] = Form.useForm()
    const [active,setActive] = useState('instagram')
    const [addCustonDeliverableActive,setAddCustomDeliverableActive] = useState(false)
    const [additionalDeliverable,setAdditionalDevliverable] = useState('')
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))


    useEffect(()=>{
        if(insagramRateCard?.length > 0){
            insagramRateCard?.map((instaRate)=>{
                if(instaRate.rate){
                form.setFieldValue({
                    [instaRate.media_type] : instaRate.rate
                })
                }
            })
        }
        if(youtubeRateCard?.length > 0){
            youtubeRateCard?.map((youtubeRate)=>{
                if(youtubeRate.rate){
                form.setFieldValue({
                    [youtubeRate.media_type] : youtubeRate.rate
                })
                }
            })
        }

    },[rateCardData,youtubeRateCard,insagramRateCard])

    const handleFormSubmit = async(values) => {
        const ratesData = Object.entries(values?.form_type)?.filter((rateDetail)=>rateDetail[1])?.map((rateDetail)=>{
            if(rateDetail && rateDetail[1]){
                return (
                    {
                        platform : active,
                        media_type : rateDetail[0],
                        rate :rateDetail[1] ?  Number(rateDetail[1]) : null
                    }
                )
            }else{
                return false
            }

        })
        const postData = {
            ratecard : ratesData
        }
        try{
            const response =rateCardData?.length > 100 ? 
            await apiClient.patch(`${BASE_URL}/pricing/${refresh_token?.user_uuid}/ratecard/`,postData)
            :
            await apiClient.patch(`${BASE_URL}/pricing/${refresh_token?.user_uuid}/ratecard/`,postData)
            if(response?.status === 200){
                notification.success({
                    message : rateCardData?.length > 0 ? 'Rate updated successfully' : 'Rate added successfully'
                })
                getRateCard()
                setVisible(false)
            }
        }catch(error){
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }

    const RateTypeOptions = [
        {
          value: 'fixedRate',
          label: 'Fixed rate',
        },
      ];
    const [selectedPlatformDeliverables, setSelectedPlatformDeliverables] = useState({
        instagram : ["image","carousel album","story","reel"],
        youtube : ["shorts","video"]
    })

    console.log(selectedPlatformDeliverables)

    return(
        <RateModalContainer>
            <Modal
            open = {visible}
            title = 'Edit Rate Card'
            onCancel={()=>{
                setVisible(false)
            }}
            footer = {null}
            width={650}
            >
                <FormContainerRate>
                <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
                <Row gutter={[12,0]}>
                    <Col span={6} style={{display : 'flex',flexDirection : 'column',gap : '12px'}}>
                        <NavigateButton 
                        onClick={()=>{setActive('instagram');setAddCustomDeliverableActive(false)}}
                        isActive = {active === 'instagram' ? true : false}
                        icon = {<img src="https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg" style={{height : '20px',width : '20px'}}/>}
                        >
                            Instagram
                        </NavigateButton>
                        <NavigateButton 
                        onClick={()=>{setActive('youtube');setAddCustomDeliverableActive(false)}}
                        isActive = {active === 'youtube' ? true : false}
                        icon = {<img src="https://static.admirian.com/fa542b51-2c5a-4a0e-9b64-ba11ac49dde4_youtube.png" style={{width : '20px',height : '20px'}}/>}
                        >
                            Youtube
                        </NavigateButton>
                    </Col>
                    <Col span={18} style={{display : 'flex',flexDirection : 'column',marginTop : '14px'}}>
                        <div span={24} style={{display : 'flex',justifyContent : 'space-between',fontFamily : 'Inter',fontSize : '14px',lineHeight : '16px'}}>
                            <span
                            style={{fontWeight : '600'}}>
                               All deliverables
                            </span>
                            {/* <a style={{color : '#1551EA',cursor : addCustonDeliverableActive ? 'not-allowed' : 'pointer'}} 
                            onClick={()=>{
                                if(!addCustonDeliverableActive){
                                    setAddCustomDeliverableActive(true)
                                }
                            }}
                            >
                                + Add custom deliverable
                            </a> */}
                        </div>
                        {addCustonDeliverableActive &&
                        <Row gutter={[20,24]} style={{display : 'flex',marginTop : '24px'}}>
                            <AdditionalDeliverableContainer>
                            <Label>
                            Deliverable name
                            </Label>
                            <Input placeholder="eg. Motion graphics" value={additionalDeliverable} onChange={(e)=>setAdditionalDevliverable(e.target.value)}/>
                            </AdditionalDeliverableContainer>
                            <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                                <CancelButton onClick={()=>{
                                    setAddCustomDeliverableActive(false)
                                }}
                                >
                                    Cancel
                                </CancelButton>
                                <SaveButton 
                                onClick={()=>{
                                    setSelectedPlatformDeliverables({
                                        ...selectedPlatformDeliverables,
                                        [active] : [
                                            ...selectedPlatformDeliverables[active],
                                            additionalDeliverable
                                    ]
                                    })
                                    setAdditionalDevliverable('')
                                    setAddCustomDeliverableActive(false)
                                        // handleChangePhone()
                                }}
                                >
                                    Save
                                </SaveButton>
                            </Col>
                        </Row>
                        }

                        <Row gutter={[20,0]} style={{display : 'flex',marginTop : '24px'}}>
                            {selectedPlatformDeliverables[active]?.map((deliverableType)=>{
                                return(
                                    <Col span={24}>
                                    <Form.Item
                                    name={['form_type',deliverableType]}
                                    label = {deliverableType}
                                    >
                                        <Input
                                        type="number"
                                        addonBefore={
                                            <Select
                                            placeholder="Rate type"
                                            style={{
                                                width: 130,
                                            }}
                                            defaultValue={'fixedRate'}
                                            options={RateTypeOptions}
                                            />
                                        }
                                        defaultValue={active === 'instagram' ? insagramRateCard?.filter((rate)=>rate?.media_type === deliverableType)?.length > 0 ? insagramRateCard?.filter((rate)=>rate?.media_type === deliverableType)[0]?.rate : '' : youtubeRateCard?.filter((rate)=>rate?.media_type === deliverableType)?.length > 0 ? youtubeRateCard?.filter((rate)=>rate?.media_type === deliverableType)[0]?.rate : ''}
                                        />
                                    </Form.Item>
                                    </Col>
                                )
                            })

                            }

                        </Row>

                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                        <CancelButton onClick={()=>{
                            setVisible(false)
                        }}
                        >
                            Cancel
                        </CancelButton>
                        <SaveButton 
                        htmlType="submit"
                        onClick={()=>{
                                // handleChangePhone()
                        }}
                        >
                            Save
                        </SaveButton>
                    </Col>
                </Row>
                </Form>
                </FormContainerRate>
            </Modal>

        </RateModalContainer>
    )
}

export default RateCardModalCreator