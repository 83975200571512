import { Avatar, Button, Checkbox, Col, Divider, Row, Space, Typography } from 'antd';
import React from 'react';
import { CardContainer, ContentDescription, ContentHeading, PricingInformation } from './style';
import { FlexContainer, FlexEndContainer, SpaceBetweenContainer } from '../../../utils/common';
import { InstagramOutlined, YoutubeFilled, SyncOutlined, PlusOutlined, CheckCircleFilled, UserOutlined } from '@ant-design/icons';
import { LocationIcon } from '../../../assets/icons';

const ProposedInfluencersListCard = ({ creatorData, handleSelectInfluencer, selectedInfluencerIds, handleRemoveInfluencer, handleRateCard, setDrawerData, setDrawerVisible }) => {
    const { name = "", profile_picture_url = "", followers, engagement, reach = "", location = "", ig_handle = "", status, categories = [], creator_uuid, yt_handle = "", starting_rate = "", creator_id, is_instagram_connected = false} = creatorData;
    const selectedInfluencerContentUI = selectedInfluencerIds.some((influencerId) => influencerId === creator_id);
    return (
        <CardContainer className={`${selectedInfluencerContentUI ? 'influencer-selected' : ''}`}>
            <CheckCircleFilled className={selectedInfluencerContentUI ? 'opacity-none' : 'opacity-full'} style={{ color: "#17A600", fontSize: 20, top: -7, left: -4, position: 'absolute' }} />
            <div>
                {
                    profile_picture_url.length ? <img
                        src={profile_picture_url}
                        width={100}
                        height={100}
                        alt="person-image"
                        style={{ borderRadius: 10, objectFit: 'cover', marginRight: 20 }}
                    /> :
                        <Avatar size={100} shape='square' icon={<UserOutlined />} style={{ marginRight: 20 }} />
                }
            </div>
            <div className='w-100'>
                <Row gutter={[20, 20]}>
                    <Col span={5}>
                        <ContentHeading>Name</ContentHeading>
                        <ContentDescription>{name}</ContentDescription>
                    </Col>
                    <Col span={5}>
                        <ContentHeading>Followers</ContentHeading>
                        <ContentDescription>{followers}</ContentDescription>
                    </Col>
                    <Col span={5}>
                        <ContentHeading>Reach</ContentHeading>
                        <ContentDescription>{reach}</ContentDescription>
                    </Col>
                    <Col span={5}>
                        <ContentHeading>Engagement</ContentHeading>
                        <ContentDescription>{engagement} %</ContentDescription>
                    </Col>
                    {/* <Col span={9}>
                        <ContentHeading>Status</ContentHeading>
                        <ContentDescription>{status}</ContentDescription>
                    </Col> */}
                    <Col span={4}>
                        <FlexEndContainer>
                            <Space>
                                {
                                    selectedInfluencerContentUI ? <Button shape="round" type='solid' onClick={() => handleRemoveInfluencer(creator_id, creator_uuid)} style={{ color: '#E9000B', fontSize: 12, background: '#fff' }}>Remove</Button> :
                                        <Button shape="round" onClick={() => handleSelectInfluencer(creator_id, creator_uuid)} icon={<PlusOutlined />} type='solid' style={{ color: "#010043", fontSize: 12, fontWeight: 500 }}>Select</Button>
                                }
                                <Button style={{ color: '#010043', fontSize: 12, border: '1px solid #010043' }} shape="round" onClick={() => { setDrawerData(creatorData); setDrawerVisible(true)}} disabled={!is_instagram_connected}>View</Button>
                            </Space>
                        </FlexEndContainer>
                    </Col>
                </Row>
                <Divider style={{ margin: '8px 0px' }} />
                <SpaceBetweenContainer>
                    <FlexContainer>
                        <Typography style={{ color: '#0F0E1B' }}><LocationIcon /> {location}</Typography>
                        <Divider type='vertical' />
                        {
                            categories.length && categories.map((media, index) => <Typography key={index}> #{media}</Typography>)
                        }
                        <Divider type='vertical' />
                        {
                            ig_handle && (<Typography><InstagramOutlined style={{ color: '#E1306C', paddingRight: 4 }} />{ig_handle}</Typography>)
                        }
                        {
                            yt_handle && (<Typography><YoutubeFilled style={{ color: '#FF0000', paddingRight: 4 }} />{yt_handle}</Typography>)
                        }
                    </FlexContainer>
                    <FlexContainer>
                        <PricingInformation>Starting from <span style={{ paddingLeft: 8, paddingRight: 8 }}>₹ {starting_rate ? `${starting_rate}` : "0"}</span></PricingInformation>
                        <Typography onClick={() => handleRateCard(creator_uuid)} style={{ color: '#1551EA', fontWeight: 500, cursor: 'pointer' }}><SyncOutlined style={{ paddingRight: 8 }} />View rates</Typography>
                    </FlexContainer>
                </SpaceBetweenContainer>
            </div>
        </CardContainer>
    )
}

export default ProposedInfluencersListCard;