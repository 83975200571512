import React from 'react';
import { isMobile } from '../../utils/constants/inedx';
import { Button, Image} from "antd";
import LeadForm from '../../components/Home/LeadForm';
import { FooterContainer, Heading6, Container} from './style';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Home/Header';

const RequestADemo = () => {
    const navigate = useNavigate();
    return (
        <Container>
           <Header isMobile = {isMobile}  headerStyle={{background: '#fff'}} /> 
        <Heading6 id='SignUp-form'>
            <div
                style={{ display: 'flex', padding: '10px', alignItems: 'center', textAlign: 'center', minWidth: !isMobile ? '400px' : '300px', flexDirection: 'column', minHeight: '400px', background: '#FFFFFF', margin: '80px 0px 20px 0px', borderRadius: '10px', boxShadow: '0px 0px 33px 16px rgba(67, 184, 206, 0.2)' }}
            >
                <div style={{ display: 'flex', width: '80%', fontWeight: 700, color: '#010043', fontSize: '32px', lineHeight: '39px', justifyContent: 'center', marginBottom: '15px' }}> Request a demo</div>
                <LeadForm />
            </div>
        </Heading6>
         <FooterContainer style={{padding : !isMobile ? '0px 80px' : '0px 20px'}}>
         <div style={{fontWeight : 700, fontSize : '18px', lineHeight : '22px', display : 'flex', alignItems : 'center'}}>
         <Image style={{height: !isMobile ? '42px' : '32px'}} preview = {false} src = "https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg"/>
         </div>

         <div style={{display : 'flex', alignItems : 'center', color : '#050360', fontSize : '14px', lineHeight : '17px'}}>
             <span onClick={()=>navigate('/privacy-policy')} style={{ cursor : 'pointer'}}>
             Privacy policy
             </span>
             {/* <span>
             Terms and Conditions
             </span> */}
         </div>
         
        </FooterContainer>
     </ Container>
    )
}

export default RequestADemo;