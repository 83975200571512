import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../utils/HelperFunctions/constants";
import axios from "axios";
import apiClient from "../services/axiosInstance";

// Async thunk to fetch influencer details
export const fetchInfluencerDetails = createAsyncThunk(
    "invite/fetchInfluencerDetails",
    async (igHandle, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(`${BASE_URL}/profiles/check-creator/`, { ig_handle: igHandle });
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Error fetching influencer details";
        return rejectWithValue(errorMessage);
      }
    }
  );

// Async thunk to send invite
export const inviteInfluencer = createAsyncThunk(
    "invite/inviteInfluencer",
    async ({ igHandle, name, email }, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(`${BASE_URL}/profiles/brand/invitations/`, {
          ig_handle: igHandle,
          name,
          email,
        });
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Error sending invite";
        return rejectWithValue(errorMessage);
      }
    }
  );

// Invite slice
const inviteSlice = createSlice({
  name: "invite",
  initialState: {
    isOpen: false,
    influencerDetails: null,
    loading: false,
    error: null,
    inviteLoading: false,
    inviteSuccess: false,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
      state.error = null;
      state.inviteSuccess = false;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.influencerDetails = null;
      state.error = null;
      state.inviteSuccess = false;
    },
    resetInviteState: (state) => {
      // Reset all state variables when modal is closed
      state.influencerDetails = null;
      state.loading = false;
      state.error = null;
      state.inviteLoading = false;
      state.inviteSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInfluencerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.influencerDetails = action.payload;
      })
      .addCase(fetchInfluencerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(inviteInfluencer.pending, (state) => {
        state.inviteLoading = true;
        state.inviteSuccess = false;
      })
      .addCase(inviteInfluencer.fulfilled, (state) => {
        state.inviteLoading = false;
        state.inviteSuccess = true;
      })
      .addCase(inviteInfluencer.rejected, (state, action) => {
        state.inviteLoading = false;
        state.error = action.payload;
      });
  },
});

export const { openModal, closeModal, resetInviteState } = inviteSlice.actions;
export default inviteSlice.reducer;
