import { useDispatch } from "react-redux";
import { Col, Input, Row,Radio, Select, Button } from "antd";
import React, { useState } from "react";
import { Container, CustomButton, Heading, SortByContainer } from "./style";
import { SearchOutlined } from '@ant-design/icons';
import UserDetails from "../UserDetails";
import { openModal } from "../../../store/inviteSlice";
import InviteInfluencerModal from "../../InviteInfluencerModal/InviteInfluencerModal";

const { Option } = Select;
const FindProfile = ({categoryList, setCategoryList,categorySelected, setCategorySelected,sortBy,setSortBy,creatorsList,useRef,lastCreatorCardRef,loadingCreators,getCategoryCount,likedUserUuid, getLikedUseIdList}) => {

    const dispatch = useDispatch();
    const removeItemAll=(arr, value)=> {
        const newArr = [...arr]
        var i = 0;
        while (i < newArr.length) {
          if (newArr[i] === value) {
            newArr.splice(i, 1);
          } else {
            ++i;
          }
        }
        setCategorySelected(newArr)
      }
    const [viewAll, setViewAll] = useState(false)

    return(
        <Container>
            <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '30px', alignItems : 'center'}}>
                <Heading>
                Discover best influencers
                </Heading>
                {/* <Input
                placeholder="Search influencer"
                prefix={<SearchOutlined />}
                /> */}
                <Button type="primary" onClick={() => dispatch(openModal())}>
                    Invite Influencer
                </Button>
            </Row>
            <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '30px', alignItems : 'flex-start'}}>
                <Col span={18} style={{display : 'flex', alignItems : 'flex-start'}}>
                    {categoryList.map(({ id, category }) => {                                     
                        return (
                            <CustomButton
                                isActive = {categorySelected.includes(id)}
                                key={id}
                                onClick={() => {
                                    categorySelected.includes(id) ? 
                                    removeItemAll(categorySelected, id) 
                                    : setCategorySelected([...categorySelected, id]) 
                                }}
                            >
                                {category}
                                </CustomButton>
                            )
                    })}
                    
                    {/* <a style={{color : '#1551EA', paddingTop : '10px'}} onClick={()=> setViewAll(!viewAll)}>{viewAll ? 'View Less' : 'View all' } </a> */}
                

                </Col>
                <Col span={6}>
                    <SortByContainer>
                    <Select
                        showArrow = {true}
                        showSearch = {true}
                        placeholder="Sort By"
                        onChange={(value)=>{setSortBy(value)}}
                        value = {sortBy}
                    >
                        <Option value="total_follower_count">Follower Count (Descending)</Option>
                        <Option value="-total_follower_count">Follower Count (Ascending)</Option>
                    </Select>
                    </SortByContainer>
                    
                </Col>
            </Row>
            <UserDetails
            creatorsList = {creatorsList}
            useRef = {useRef}
            lastCreatorCardRef ={lastCreatorCardRef}
            loadingCreators = {loadingCreators}
            getCategoryCount = {getCategoryCount}
            likedUserUuid = {likedUserUuid}
            getLikedUseIdList ={getLikedUseIdList}
            />
            <InviteInfluencerModal />
        </Container>
    )

}

export default FindProfile