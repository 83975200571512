import { Button, Form, Input, notification, Radio } from "antd";
import React, { useState } from "react";
import { CustomInputPassword, FormContainer,RadioContainer } from "./style";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import TextArea from "antd/es/input/TextArea";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";
const LeadForm = () => {
    const [form] = Form.useForm();

    const isNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    const handleSubmit = async(values)=> {
        const phoneNumber = "+91".concat(values?.phone)
        const postdata = {
            email : values?.email,
            name : values?.name,
            phone_number : phoneNumber,
            query : values?.query
        }
        try{
            const response = await apiClient.post(`${BASE_URL}/commons/lead/`,
            postdata)

            if(!response?.data?.errors){
                notification.success({
                    message : response?.data?.message
                })

                // SignIn(values)
                // navigate('/Sign-in/flow')
            }
            else{
                notification.error({
                    message : response?.data?.message
                })
            }

        }catch(error){
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }

    return(
        <FormContainer>
        <Form
        style={{width : '80%'}}
        layout="vertical" 
        form={form}
        onFinish = {handleSubmit}
        >
            <Form.Item
                label='Email'
                name="email"

                rules={[
                { 
                    required: true,
                    type : "email",
                    message: 'Email is required' 
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
            name="name"
            label="Name"
            >
                <Input/>
            </Form.Item>
            <Form.Item
            name='phone'
            label = 'Phone Number'
            rules={[
                { 
                    required: true,
                    type : "string",
                    message: 'Phone number is required' 
                },
                ]}
            >
                <Input
                maxLength={10}
                placeholder="10 digits [0-9] phone number"
                onInput={isNumber}
                />
            </Form.Item>
            <Form.Item
            name='query'
            label = 'Any queries ?'
            >
                <TextArea
                style={{height: "130px", padding : '20px'}}
                />
            </Form.Item>
            <div style={{display : 'flex', justifyContent: 'center', alignItems : 'center', width : '100%', marginBottom : '25px'}}>
                <Button
                style={{display: 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column',color : '#FFFFFF', background:'#F86624', borderRadius : '70px', width : '100%', height : '55px'}}
                htmlType="submit"
                >
                    <span style={{fontWeight : 700, fontSize : '20px',lineHeight: '24px'}}>Submit</span>
                    {/* <span style={{fontSize : '12px',lineHeight: '14px'}}>You’re one click way</span> */}
                </Button>
            </div>
            {/* <div style={{display : 'flex', justifyContent: 'center', alignItems : 'center', width : '100%',flexDirection:'column'}}>
                <span style={{color :'#010043', opacity :'0.5',fontSize : '14px',lineHeight : '17px', fontWeight : 400}}>
                Direct integrated with
                </span>
                <img src="https://static.admirian.com/13c3b303-b70d-411f-a6aa-7d7514c1446e_Meta.png"/>
            </div> */}
        </Form>
    </FormContainer>
    )

}

export default LeadForm