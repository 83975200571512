import { Col, Divider, Form, Input, Modal, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, FormContainer, FormContainerProfileModal, ModalTitle, SaveButton } from "./style";
import { useForm } from "antd/es/form/Form";
import { parseJwtToken } from "../../utils";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { BASE_URL } from "../../utils/HelperFunctions/constants";


const { Option } = Select;
const UpdateBasicInfoModal = ({visible,setVisible,brandDetails,getBrandDetail,categoryList}) =>{


    const [form] = Form.useForm()
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

    const handleFormSubmit = async(values) => {
      let postData = refresh_token?.user_type === "Brand" ? {
        brand_name: values.name,
      }   
      : {
        name : values.name,
      } 
      if(values?.category){
        postData = {
            ...postData,
            categories: values.category,
        }
      }
      try{
          const response =refresh_token?.user_type === "Brand" ? await apiClient.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
          postData
          )
          : 
          await apiClient.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`,
          postData
          )

          if(!response?.data?.error){
              notification.success({
                  message : "Profile updated successfully"
              })
              getBrandDetail()
              setVisible(false)
              form.resetFields()
              // carouselRef.current.next()
          }
          else{
              notification.error({
                  message : response?.data?.error?.message
              })
          }
          
      }catch(error){
          notification.error({message : error?.response?.data?.message})
      }
    }

    useEffect(()=>{
        if(refresh_token?.user_type === "Brand"){
            if(brandDetails){
                form.setFieldsValue({
                    name : brandDetails?.brand_name,
                })
            }else{
                form.resetFields()
            }
        }
        else{
            if(brandDetails){
                form.setFieldsValue({
                    name : brandDetails?.name,
                })
            }else{
                form.resetFields()
            }
        }
    },[brandDetails, visible])


    return(
        <Modal
        title = {<ModalTitle>Profile Details</ModalTitle>}
        visible = {visible}
        destroyOnClose = {true}
        onCancel = {()=>{
            setVisible(false)
        }}
        footer = {false}
        >
            <FormContainerProfileModal>
            <Form
            form={form}
            layout="vertical"
            onFinish = {handleFormSubmit}
            >
                <Row style={{marginTop : '30px'}}>
                    <Col span={24}>
                        <Form.Item
                        name="name"
                        label={`${refresh_token?.user_type === "Brand" ? "Brand Name" : "Name"}`}
                        rules={[
                            {
                            required: true,

                            message: 'Brand Name is required',
                            },
                        ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        name="category"
                        label="Category"
                        >
                            <Select 
                                    mode="multiple"
                                    placeholder="Select Category"
                                    maxTagCount= 'responsive'
                                    onChange={(value)=>{
                                        form.setFieldsValue({
                                            category: value
                                        })
                                    }}
                                    >
                                        {categoryList.map(({ id, category }) => {                                     
                                        return (
                                            <Option value={id}>{category}</Option>
                                        )
                                        })}
                                    </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                    <CancelButton onClick={()=>{
                        form.resetFields()
                        setVisible(false)
                    }}
                    >
                        Cancel
                    </CancelButton>
                    <SaveButton 
                    htmlType="submit"
                    >
                        Save Changes
                    </SaveButton>
                </Col>
            </Form>
            </FormContainerProfileModal>

        </Modal>
    )

}

export default UpdateBasicInfoModal