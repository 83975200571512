import { Button, Form, Input, notification, Radio } from "antd";
import React, { useState, useEffect } from "react";
import { CustomInputPassword, FormContainer, RadioContainer } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";

const SignUpForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, search } = location;
    const [form] = Form.useForm();
    const [inviteToken, setToken] = useState(null); // Store token from URL

    // Extract inviteToken from URL on component mount
    useEffect(() => {
        const params = new URLSearchParams(search);
        const urlToken = params.get("invite_token"); // Extract the "invite_token" parameter
        if (urlToken) {
            setToken(urlToken); // Save token in state
        }
    }, [search]); // Runs when URL search params change

    const handleSubmit = async (values) => {
        const postdata = {
            email: values?.email,
            password: values?.password,
            user_type: Number(role),
        }

        // Only add "invite_token" if it's present
        if (inviteToken) {
            postdata.invite_token = inviteToken;
        }
        try {
            const response = await apiClient.post(`${BASE_URL}/users/register/`,
                postdata)

            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message
                })
                navigate('/verify-otp',
                    {
                        state: {
                            email: values?.email,
                            password: values?.password
                        }
                    })
            }
            else {
                notification.error({
                    message: response?.data?.message
                })
            }

        } catch (error) {
            notification.error({
                message: error?.response?.data?.message
            })
        }
    }
    const [role, setRole] = useState(pathname.toLowerCase() === "/brands/elite/register" ? "1" : "2")
    const RadioGroup = ({ role, setRole }) => {
        return (
            <RadioContainer style={{textAlign: 'left'}} className="mb-16">
                <Radio.Group
                    defaultValue="1"
                    buttonStyle="solid"
                    value={role}
                    // onChange={(e) => {
                    //     setRole(e.target.value)
                    //     form.setFieldsValue({
                    //         role: e.target.value
                    //     })
                    // }}
                >
                    {
                        pathname.toLowerCase() === "/brands/elite/register" ? <Radio.Button className="selected-radio-button" value={1}>
                            <span style={{ display: "flex", flexDirection: 'row', lineHeight: '50px' }}><img style={{ marginRight: '5px' }} src={role == 1 ? "https://static.admirian.com/834e9030-f039-4539-bb5a-16f641666809_checkedTick.svg" : "https://static.admirian.com/483767d2-18db-4edf-8082-827e0af11f37_uncheckTick.svg"} />  Brand</span>
                        </Radio.Button> : <Radio.Button className="selected-radio-button" value={2}>
                            <span style={{ display: "flex", flexDirection: 'row', lineHeight: '50px' }}><img style={{ marginRight: '5px' }} src={role == 2 ? "https://static.admirian.com/834e9030-f039-4539-bb5a-16f641666809_checkedTick.svg" : "https://static.admirian.com/483767d2-18db-4edf-8082-827e0af11f37_uncheckTick.svg"} />  Influencer</span>
                        </Radio.Button>
                    }


                </Radio.Group>
            </RadioContainer>
        );
    };

    return (
        <FormContainer>
            <Form
                className="w-100"
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label='Email'
                    name="email"

                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: 'Email is required'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,

                            message: 'Password is required',
                        },
                    ]}
                >
                    <CustomInputPassword />
                </Form.Item>
                <Form.Item
                    name="role"
                    label="Select role"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Role is required',
                    //     },
                    // ]}
                >
                    <RadioGroup role={role} setRole={setRole} />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '16px' }}>
                    <Button
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: '#FFFFFF', background: '#F86624', borderRadius: '70px', width: '100%', height: '55px' }}
                        htmlType="submit"
                    >
                        <span style={{ fontWeight: 700, fontSize: '20px', lineHeight: '24px' }}>Join Now</span>
                        <span style={{ fontSize: '12px', lineHeight: '14px' }}>You’re one click way</span>
                    </Button>
                </div>
                {/* <div style={{display : 'flex', justifyContent: 'center', alignItems : 'center', width : '100%',flexDirection:'column'}}>
                <span style={{color :'#010043', opacity :'0.5',fontSize : '14px',lineHeight : '17px', fontWeight : 400}}>
                Direct integrated with
                </span>
                <img src="https://static.admirian.com/13c3b303-b70d-411f-a6aa-7d7514c1446e_Meta.png"/>
            </div> */}
            </Form>
        </FormContainer>
    )

}

export default SignUpForm