import React, { Fragment, useEffect, useState } from "react";
import { AmountText, Container, EstimationText, InfluencerFooter, ViewSummaryText, BackButtonText } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { AdmirianButton, FlexContainer, Heading, JustifyCenterContainer, SpaceBetweenContainer, SubHeading } from "../../../utils/common";
import ProposedInfluencersListCard from "./ProposedInfluencersListCard";
import { useAllInfluencersInitiatedMutation, useGetInfluencerSummaryQuery, usePaymentInitiatedMutation } from "../../../services/manageCampaignsApi";
import RatecardModal from "../InfluencerDetailListCard/RatecardModal";
import { notification } from "antd";
import { parseJwtToken, removeElementFromArray } from "../../../utils";
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";
import { CustomDrawer } from "../../Dashboard/CustomDrawer";

const ProposedInfluencers = () => {
    const { id, creatorUuidArray } = useParams();
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const { data: { campaign_creators = [] } = {} } = useGetInfluencerSummaryQuery({ campaignId: id, status: ['ACCEPTED'] });
    const [allInfluencersInitiatedMutation] = useAllInfluencersInitiatedMutation();
    const [paymentInitiatedMutation] = usePaymentInitiatedMutation();
    const [selectedInfluencerIds, setSelectedInfluencerIds] = useState([]);
    const [selectedInfluencerId, setSelectedInfluencerId] = useState("");
    const [proposedInfluencerDetails, setProposedInfluencerDetails] = useState([]);
    const [brandDetails, setBrandDetails] = useState({})
    const [selectedInfluencerData, setSelectedInflencerData] = useState([]);
    const [drawerData, setDrawerData] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedCreatorCampaignId, setSelectedCreatorCampaignId] = useState([]);
    const [rateCardModal, setRateCardModal] = useState(false);
    const [paymentButtonLoading, setPaymentButtonLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();

    const getBrandDetail = async () => {
        try {
            const response = await apiClient.get(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`)

            if (!response?.data?.error) {
                setBrandDetails(response?.data)
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    useEffect(() => {
        if (refresh_token?.user_type === "Brand") {
            getBrandDetail()
        }
    }, [])

    useEffect(() => {
        async function fetchInfluencerData() {
            const filters = { "creator_uuids": creatorUuidArray.split(",") };
            const response = await allInfluencersInitiatedMutation({ "filters": filters });
            try {
                if (response && !response.error) {
                    setProposedInfluencerDetails(response?.data?.creators)
                } else if (response.error) {
                    notification.error({
                        message: response?.error?.data?.message
                    })
                }
            } catch (error) {
                notification.error({
                    message: error?.response?.error?.data?.message
                })
            }
        }
        fetchInfluencerData();
    }, [])


    const handleSelectInfluencer = (creatorId, creatorUuid) => {
        const creatorIdData = [...selectedInfluencerIds, creatorId];
        const selectedInfluencer = proposedInfluencerDetails.filter((influencer) => influencer.creator_id === creatorId);
        const selectedInfluencerDetail = campaign_creators.filter((creator) => creator.creator_uuid === creatorUuid);
        const selectedCampaignCreatorIdArray = selectedInfluencerDetail.map((item) => item.campaign_creator_id);
        // setTotalAmount(totalAmount + selectedInfluencerDetail[0]?.approved_rate);
        setTotalAmount(totalAmount + selectedInfluencerDetail[0]?.campaign_rate);
        setSelectedInfluencerIds(creatorIdData);
        setSelectedInflencerData([...selectedInfluencer, ...selectedInfluencerData]);
        // setSelectedCreatorCampaignId(prevIds => [...prevIds, ...selectedCampaignCreatorIdArray]);
        setSelectedCreatorCampaignId([...selectedCreatorCampaignId,...selectedCampaignCreatorIdArray])
    }

    const handleRemoveInfluencer = (creatorId, creatorUuid) => {
        const creatorIdData = selectedInfluencerIds.filter((influencerId) => influencerId !== creatorId);
        const selectedInfluencer = selectedInfluencerData.filter((influencer) => influencer.creator_id !== creatorId);
        const selectedInfluencerDetail = campaign_creators.filter((creator) => creator.creator_uuid === creatorUuid);
        removeElementFromArray(selectedCreatorCampaignId, selectedInfluencerDetail[0].campaign_creator_id);
        // setTotalAmount(totalAmount - selectedInfluencerDetail[0]?.approved_rate);
        setTotalAmount(totalAmount - selectedInfluencerDetail[0]?.campaign_rate);
        setSelectedInflencerData([...selectedInfluencer]);
        setSelectedInfluencerIds([...creatorIdData])
        setSelectedCreatorCampaignId([...selectedCreatorCampaignId])
    }

    const handleRateCard = (id) => {
        setSelectedInfluencerId(id);
        setRateCardModal(true);
    }

    const handleMakePayment = () => {
        navigate(`/payments/${id}/${totalAmount}/${selectedCreatorCampaignId}`)
    }

    const handlePostpaidPayment = async () => {
        const paymentDataToSend = {
            "campaign_creator_ids": selectedCreatorCampaignId,
            "payment_mode": "Postpaid",
            "reference": "Postpaid"
        }
        if (Number(totalAmount) !== 'NaN') {
            paymentDataToSend.amount = Number(totalAmount)
        }
        const response = await paymentInitiatedMutation({ campaignId: id, paymentDataToSend });
        setPaymentButtonLoading(true);
        try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                navigate(`/manage-campaign-view/${id}`)
                setPaymentButtonLoading(false);
            } else {
                notification.error({
                    message: response?.error?.data?.error
                })
                setPaymentButtonLoading(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setPaymentButtonLoading(false);
        }
    }

    const onClose = () => {
        setDrawerData('')
        setDrawerVisible(false)
    }

    return (
        <>
            <Container>
                <Heading>Ready to Collaborate: Accepted Proposals</Heading>
                <SubHeading>These influencers have agreed to join your campaign. Here, you may review and select the ones you are ready to collaborate with. Bring your campaign to Life!</SubHeading>
                {
                    proposedInfluencerDetails.map((creatorData) => {
                        return (
                            <Fragment key={creatorData.creator_id}>
                                <ProposedInfluencersListCard creatorData={creatorData} setDrawerData={setDrawerData} selectedInfluencerIds={selectedInfluencerIds} handleSelectInfluencer={handleSelectInfluencer} handleRemoveInfluencer={handleRemoveInfluencer} handleRateCard={handleRateCard} setDrawerVisible={setDrawerVisible} />
                            </Fragment>
                        )
                    })
                }
            </Container>
            <InfluencerFooter>
                <SpaceBetweenContainer style={{ padding: "16px 24px", alignItems: 'center' }}>
                    <FlexContainer className="cursor-pointer" onClick={() => navigate(-1)}>
                        <LeftOutlined />
                        <BackButtonText>Back</BackButtonText>
                    </FlexContainer>
                    <FlexContainer>
                        <div>
                            <EstimationText>Cost</EstimationText>
                            <ViewSummaryText>Inclusive of Admirian Platform Fee</ViewSummaryText>
                        </div>
                        <AmountText>₹ {totalAmount}</AmountText>
                        <div>
                            {
                                brandDetails?.payment_style?.toLowerCase() === "postpaid" ? <AdmirianButton onClick={handlePostpaidPayment} size='large' shape='round' loading={paymentButtonLoading} disabled={!totalAmount}>Continue</AdmirianButton> : <AdmirianButton onClick={handleMakePayment} size='large' shape='round' disabled={!totalAmount}>Make Payment</AdmirianButton>
                            }
                        </div>
                    </FlexContainer>
                </SpaceBetweenContainer>
                {
                    rateCardModal && (
                        <RatecardModal selectedInfluencerId={selectedInfluencerId} rateCardModal={rateCardModal} setRateCardModal={setRateCardModal} />
                    )
                }
            </InfluencerFooter>
            <div>
                {
                    drawerVisible && (

                        <CustomDrawer
                            onClose={onClose}
                            drawerVisible={drawerVisible}
                            drawerData={drawerData}
                            hideConnectionButton={true}
                        // likedUserUuid={likedUserUuid}
                        // getLikedUseIdList={getLikedUseIdList}
                        // getCategoryCount={getCategoryCount}
                        />
                    )
                }
            </div>
        </>
    )
}
export default ProposedInfluencers;