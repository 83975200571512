import { Button, Col, Drawer, Row, Spin, Tag, notification } from "antd";
import React, { useState } from "react";
import { CategoryButton, ImageContainer, UserCardContainer, ViewMoreButton, Container, SubHeading } from "./style";
import { HeartOutlined , HeartFilled} from '@ant-design/icons';
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import { parseJwtToken } from "../../../utils";
import Analytics from "../../../pages/Analytics";
import { CustomDrawer } from "../CustomDrawer";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";


const UserDetails = ({creatorsList, useRef, lastCreatorCardRef, loadingCreators,getCategoryCount, likedUserUuid, getLikedUseIdList}) => {

    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

    const [isHovering, setIsHovering] = useState('');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerData, setDrawerData] = useState(null)

    const handleAddLike = async(creator_uuid) => {
        try{
            const response = await apiClient.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creator/${creator_uuid}/`)
            if(!response?.data?.error){
                notification.success({
                    message : response?.data?.message
                })
                getCategoryCount()
                getLikedUseIdList()
            }
            else{
                notification.error({
                    message : response?.data?.message
                })
            }
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
    }

    const handleRemoveLike = async(creator_uuid) => {
        try{
            const response = await apiClient.delete(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creator/${creator_uuid}/`)
            if(!response?.data?.error){
                notification.success({
                    message : response?.data?.message
                })
                getCategoryCount()
                getLikedUseIdList()
            }
            else{
                notification.error({
                    message : response?.data?.message
                })
            }
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
    }

    const onClose =() => {
        setDrawerData('')
        setDrawerVisible(false)
    }
    return(
        <>
        <Row gutter={[20,20]} style={{display : 'flex', width : '100%'}}>
            {creatorsList.length > 0 ? 
                creatorsList?.map((creatorDetails,index)=>{
                    if(creatorsList.length === index+1){
                        return(
                            <Col ref={lastCreatorCardRef} key={creatorDetails?.creator_uuid} span={6}>
                                <UserCardContainer>
                                    <ImageContainer 
                                    onMouseOver={()=>{
                                        setIsHovering(creatorDetails?.creator_uuid)
                                    }}
                                    onMouseOut={()=>{
                                        setIsHovering('')
                                    }} 
                                    backGroundImageUrl = {creatorDetails?.profile_picture_url}
                                    >
                                    {isHovering !== creatorDetails?.creator_uuid  ?
                                        <>
                                            {/* <Tag color="#EA3546">Rising</Tag> */}
                                            <div style={{display : 'flex', width : '100%',flexDirection : 'column'}}>
                                                <div style={{display : 'flex',width : '100%', fontSize : '14px',color : '#FFFFFF', marginBottom : '8px'}}><img src="https://static.admirian.com/d94ad24b-bf2b-4960-8a79-8a8eaf0ccb76_locationLogo.svg"/> {creatorDetails?.location}</div>
                                                <div style={{display : 'flex',width : '100%',}}>
                                                    {creatorDetails?.categories[0] &&
                                                    <CategoryButton>
                                                        {creatorDetails?.categories[0]}
                                                    </CategoryButton>}
                                                    {creatorDetails?.categories[1] &&
                                                    <CategoryButton>
                                                    {creatorDetails?.categories[1]}
                                                    </CategoryButton>}
                                                </div>
                                            </div>
                                        </>
                                        :   
                                        <div style={{display : 'flex',flexDirection :'column', alignItems : "center",width : '100%'}}>
                                            
                                            <Button
                                            icon = {likedUserUuid?.includes(creatorDetails?.creator_uuid) ? <HeartFilled style={{color : 'red'}} /> : <HeartOutlined/>} 
                                            style={{width : '60px', height : '60px', border : '0px', borderRadius : '60px', background : '#FFFFFF', marginBottom : '25px'}}
                                            onClick={()=>{
                                                likedUserUuid?.includes(creatorDetails?.creator_uuid) ?
                                                    handleRemoveLike(creatorDetails?.creator_uuid)
                                                :
                                                    handleAddLike(creatorDetails?.creator_uuid)
                                            }}
                                            >
                                            </Button>
                                            {creatorDetails?.is_instagram_connected && (
                                                <ViewMoreButton
                                                    onClick={()=>{
                                                        setDrawerVisible(true)
                                                        setDrawerData(creatorDetails)
                                                    }}
                                                >
                                                    View More
                                                </ViewMoreButton>
                                            )}
                                        </div>
                                        }
                                    </ImageContainer>
                                    <Row style={{display:'flex',width : '100%', justifyContent : 'space-between', alignItems : 'center'}}>
                                        <Col span={12} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'14px',color : '#010043'}}>
                                            {`${creatorDetails?.name}`}
                                            </span>
                                        </Col>
                                        <Col span={12} style={{display : 'flex', justifyContent : 'flex-end'}}>
                                            <img src="https://static.admirian.com/8fc5caac-833f-4dd7-a6d2-c9d325ca15dc_socialMedia.svg"/>
                                        </Col>
                                    </Row>
                                    <Row style={{display:'flex',width : '100%', justifyContent : 'space-between', alignItems : 'center'}}>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {creatorDetails?.followers ? creatorDetails?.followers : '-'}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Followers
                                            </span>
                                        </Col>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {creatorDetails?.reach ? creatorDetails?.reach : '-'}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Reach
                                            </span>
                                        </Col>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {`${creatorDetails?.engagement ? `${creatorDetails?.engagement}%` : '-'}`}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Engagement
                                            </span>
                                        </Col>
                                    </Row>
                                </UserCardContainer>
                            </Col>
                        )
                    }else{
                        return(
                            <Col key={creatorDetails?.creator_uuid} span={6}>
                                <UserCardContainer>
                                    <ImageContainer 
                                    onMouseOver={()=>{
                                        setIsHovering(creatorDetails?.creator_uuid)
                                    }}
                                    onMouseOut={()=>{
                                        setIsHovering('')
                                    }}  
                                    backGroundImageUrl = {creatorDetails?.profile_picture_url}
                                    >
                                    {isHovering !== creatorDetails?.creator_uuid  ?
                                        <>
                                            {/* <Tag color="#EA3546">Rising</Tag> */}
                                            <div style={{display : 'flex', width : '100%',flexDirection : 'column'}}>
                                                <div style={{display : 'flex',width : '100%', fontSize : '14px',color : '#FFFFFF', marginBottom : '8px'}}><img src="https://static.admirian.com/d94ad24b-bf2b-4960-8a79-8a8eaf0ccb76_locationLogo.svg"/> {creatorDetails?.location}</div>
                                                <div style={{display : 'flex',width : '100%',}}>
                                                    {creatorDetails?.categories[0] &&
                                                        <CategoryButton>
                                                            {creatorDetails?.categories[0]}
                                                        </CategoryButton>
                                                    }
                                                    {creatorDetails?.categories[1] &&
                                                        <CategoryButton>
                                                        {creatorDetails?.categories[1]}
                                                        </CategoryButton>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :   
                                        <div style={{display : 'flex',flexDirection :'column', alignItems : "center",width : '100%'}}>
                                            
                                            <Button
                                            icon = {likedUserUuid?.includes(creatorDetails?.creator_uuid) ? <HeartFilled style={{color : 'red'}} /> : <HeartOutlined />} 
                                            style={{width : '60px', height : '60px', border : '0px', borderRadius : '60px', background : '#FFFFFF', marginBottom : '25px'}}
                                            onClick={()=>{
                                                likedUserUuid?.includes(creatorDetails?.creator_uuid) ?
                                                handleRemoveLike(creatorDetails?.creator_uuid)
                                                :
                                                handleAddLike(creatorDetails?.creator_uuid)
                                            }}
                                            >
                                            </Button>
                                            {creatorDetails?.is_instagram_connected && (
                                                <ViewMoreButton
                                                    onClick={()=>{
                                                        setDrawerVisible(true)
                                                        setDrawerData(creatorDetails)
                                                    }}
                                                >
                                                    View More
                                                </ViewMoreButton>
                                            )}

                                        </div>
                                        }
                                    </ImageContainer>
                                    <Row style={{display:'flex',width : '100%', justifyContent : 'space-between', alignItems : 'center'}}>
                                        <Col span={12} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'14px',color : '#010043'}}>
                                            {`${creatorDetails?.name}`}
                                            </span>
                                        </Col>
                                        <Col span={12} style={{display : 'flex', justifyContent : 'flex-end'}}>
                                            <img src="https://static.admirian.com/8fc5caac-833f-4dd7-a6d2-c9d325ca15dc_socialMedia.svg"/>
                                        </Col>
                                    </Row>
                                    <Row style={{display:'flex',width : '100%', justifyContent : 'space-between', alignItems : 'center'}}>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {creatorDetails?.followers ? creatorDetails?.followers : '-'}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Followers
                                            </span>
                                        </Col>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {creatorDetails?.reach ? creatorDetails?.reach : '-'}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Reach
                                            </span>
                                        </Col>
                                        <Col span={8} style={{display : 'flex', flexDirection : 'column'}}>
                                            <span style={{fontWeight : 600,fontSize :'15px',color : '#010043'}}>
                                            {`${creatorDetails?.engagement ? `${creatorDetails?.engagement}%` : '-'}`}
                                            </span>
                                            <span style={{fontSize :'12px',color : '#01004399'}}>
                                            Engagement
                                            </span>
                                        </Col>
                                    </Row>
                                </UserCardContainer>
                            </Col>
                        )
                    }
                })
            :   
            !loadingCreators && 
            <div style={{display : 'flex',justifyContent : "center" ,width : '100%'}}>
                <Container>
                    <img src='https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg' width={150} height={150} />
                    <SubHeading>No influencers found matching the applied filters. Please modify your search criteria and try again</SubHeading>
                </Container>
            </div>
            }
            {loadingCreators && 
            <div style={{display : 'flex', justifyContent : "center",width : '100%'}}>
                <Spin/>
            </div>
            
            }
        </Row>

        <CustomDrawer
        onClose= {onClose}
        drawerVisible = {drawerVisible}
        drawerData ={drawerData}
        likedUserUuid = {likedUserUuid}
        getLikedUseIdList = {getLikedUseIdList}
        getCategoryCount ={getCategoryCount}
        />
      </>
    )

}

export default UserDetails