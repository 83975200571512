import { Carousel, Row, Form, Input, Select, Upload, notification, Radio, DatePicker, Image } from "antd";
import React, { useEffect, useState } from "react";
import { BasicInformationHeading, CarouselBox, CarouselContainer, CustomButton, DoThisLater, ExploreInformationText, GetStartedText } from "./style";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { parseJwtToken } from "../../utils";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { AdmirianButton, JustifyCenterContainer } from "../../utils/common";
import Footer from "../../components/Footer";

const { Option } = Select;
const SignInFlow = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
    const [name, setName] = useState("");
    const [instaName, setInstaName] = useState("");
    const [youtubeName, setYoutubeName] = useState('');
    const [profileImage, setProfileImage] = useState([]);
    const [formProfileImage, setFormProfileImage] = useState(null);
    const refresh_token = parseJwtToken(localStorage.getItem("refresh_token"));
    const [location, setLocation] = useState("");
    const [date, setDate] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [categorySelected, setCategorySelected] = useState([]);
    const carouselRef = React.useRef();

    const disabledDate = (current) => {
        return current > moment().subtract(13, "years");
    };

    const settings = {
        infinite: false,
        centerMode: true,
        lazyLoad: true,
        slidesToShow: 1,
        centerPadding: 0,
        // afterChange: (currentSlide) => {console.log({currentSlide})},
        // beforeChange: (currentSlide) => {
        //     console.log({ currentSlide });
        // },
        speed: 300,
        ref: carouselRef
    };

    const getCategoryList = async () => {
        const response = await apiClient.get(`${BASE_URL}/profiles/categories/`);
        if (!response?.data?.error) {
            setCategoryList(response?.data);
        }
    };
    useEffect(() => {
        getCategoryList();
    }, []);

    const profileImageProps = {
        onRemove: (file) => {
            setProfileImage([]);
            setFormProfileImage(null);
        },
        beforeUpload: async (file) => {
            if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
                setProfileImage([file]);
                setFormProfileImage(file);
                return false;
            } else {
                notification.error({
                    message: "You can only upload png/jpeg/jpg file!"
                });
                return Upload.LIST_IGNORE;
            }
        }
    };

    const removeItemAll = (arr, value) => {
        const newArr = [...arr];
        var i = 0;
        while (i < newArr.length) {
            if (newArr[i] === value) {
                newArr.splice(i, 1);
            } else {
                ++i;
            }
        }
        setCategorySelected(newArr);
    };

    const handleSelectChange = (value) => {
        setCategorySelected(value);
    };

    useEffect(() => {
        form.setFieldsValue({
            Category: categorySelected
        });
    }, [categorySelected]);

    const handleContinueForm = () => {
        const values = form.getFieldsValue()
        if (refresh_token?.user_type === "Brand") {
            if (!values?.Website || !values?.phone) {
                notification.error({
                    message: 'Please fill all the fields'
                })
            }
            else if (values?.phone?.length !== 10) {
                notification.error({
                    message: 'Please enter valid Phone number'
                })
            }
            else {
                //carouselRef.current.next()
                setCarouselActiveIndex(2);
                carouselRef.current.next();
            }
        }
        else {
            if (!values?.country || !values?.dob || !values?.gender) {
                notification.error({
                    message: 'Please fill all the fields'
                })
            }
            else {
                setCarouselActiveIndex(2);
                carouselRef.current.next();
            }
        }

    };
    const handleContinue = async (value) => {
        const values = form.getFieldsValue();
        if (name.trim() === "") {
            notification.error({
                message: 'Name cannot be empty'
            })
            return
        }
        if (values?.phone === undefined && refresh_token?.user_type !== "Brand") {
            notification.error({
                message: 'Phone number cannot be empty'
            })
            return
        }
        if (values?.phone?.length !== 10 && refresh_token?.user_type !== "Brand") {
            notification.error({
                message: 'Please enter valid Phone number'
            })
            return
        }
        if ((values?.ig_handle === undefined || values?.ig_handle?.length === 0) && refresh_token?.user_type !== "Brand") {
            notification.error({
                message: 'Your Instagram handle cannot be empty'
            })
            return
        }
        let formData = new FormData();
        refresh_token?.user_type === "Brand" ?
            formData.append("brand_name", name)
            :
            formData.append("name", name);
        if (refresh_token?.user_type !== "Brand") {
            formData.append("phone_number", `+91${values.phone}`);
            let platformHandles = {};
            if (instaName) {
                platformHandles.instagram = `@${instaName}`;
            }
            if (values?.yt_handle !== undefined && values?.yt_handle?.length > 0) {
                platformHandles.youtube = `@${youtubeName}`;
            } 
            if (Object.keys(platformHandles).length > 0) {
                formData.append("platform_handles", JSON.stringify(platformHandles));
            }
        }
        if (profileImage.length >= 1) {
            refresh_token?.user_type === "Brand" ?
                formData.append("logo", formProfileImage)
                :
                formData.append("image", formProfileImage)
        }
        try {
            const response = refresh_token?.user_type === "Brand" ? await apiClient.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
                formData
            )
                : await apiClient.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`,
                    formData
                )

            if (!response?.data?.error) {
                setCarouselActiveIndex(1);
                carouselRef.current.next();

                // Now, make the second API call to /profiles/setup
                if (instaName) {
                    try {
                        await apiClient.post(`${BASE_URL}/profiles/setup/`, {
                            ig_handle: `${instaName}`
                        });
                    } catch (setupError) {
                        notification.error({
                            message: "Failed to complete profile setup",
                        });
                    }
                }
            }
            else {
                notification.error({
                    message: response?.data?.error?.message
                })
            }

        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    };

    const handleSubmit = async (value) => {
        const values = form.getFieldsValue();
        let formData = new FormData();
        const postData =
            refresh_token?.user_type === "Brand"
                ? {
                    website: `${values?.Website?.slice(0, 4).toLowerCase() === "http" ? values?.Website : `https://${values?.Website}`}`,
                    categories: values?.Category,
                    phone_number: `+91${values?.phone}`
                }
                : {
                    gender: values?.gender,
                    date_of_birth: date,
                    location: values?.country,
                    categories: values?.Category
                };
        try {
            const response =
                refresh_token?.user_type === "Brand"
                    ? await apiClient.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`, postData)
                    : await apiClient.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, postData);

            if (!response?.data?.error) {
                setCarouselActiveIndex(3);
                carouselRef.current.next();
                setTimeout(() => {
                    if (refresh_token?.user_type === "Brand") {
                        navigate(`/dashboard`);
                    } else {
                        navigate(`/analytics`);
                    }
                }, 3000);
            } else {
                notification.error({
                    message: response?.data?.error?.message
                });
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message });
        }
    };
    const isNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
    };
    const uploadButton = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#4E4BEE",
                height: "190px",
                width: "190px",
                borderRadius: "200px",
                flexDirection: "column"
            }}>
            {<img src="https://static.admirian.com/fbde7fc1-ddb9-4706-ac2d-5f083c74685b_addLogo.svg" alt="Upload" />}
            <div
                style={{
                    marginTop: 8,
                    color: "#FFFFFF",
                    fontSize: "14px"
                }}>
                {refresh_token?.user_type === "Brand" ? "Add Logo" : "Add Photo"}
            </div>
        </div>
    );

    return (
        <div>
            <JustifyCenterContainer className="w-100" style={{ minHeight: "50px", alignItems: "center", padding: "6px 0px" }}>
                <Image height={50} style={{ margin: "4px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="brand-logo" />
            </JustifyCenterContainer>
            <CarouselContainer>
                <Form className="w-100" layout="vertical" form={form} onFinish={handleSubmit}>
                    <Carousel {...settings}>
                        <CarouselBox>

                            <Upload name="avatar" listType="picture-card" className="avatar-uploader" {...profileImageProps} maxCount={1} previewFile={false} fileList={profileImage}>
                                {profileImage.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Form.Item name="Name" className="w-100" label={refresh_token?.user_type === "Brand" ? "Brand Name" : "Name"} required>
                                <Input onChange={(e) => setName(e.target.value)} />
                            </Form.Item>
                            {
                                refresh_token?.user_type !== "Brand" && (
                                <>
                                    <Form.Item name="phone" label="Phone Number" className="w-100" required>
                                        <Input addonBefore="+91" placeholder="10 digits [0-9] phone number" onInput={isNumber} />
                                    </Form.Item>
                                    <Form.Item name="ig_handle" label="Your Instagram Handle" className="w-100" required>
                                        <Input addonBefore="@" onChange={(e) => setInstaName(e.target.value)} />
                                    </Form.Item>
                                    <Form.Item name="yt_handle" label="Your Youtube Handle" className="w-100">
                                        <Input addonBefore="@" onChange={(e) => setYoutubeName(e.target.value)} />
                                    </Form.Item>
                                </>
                                )
                            }
                            <AdmirianButton size="large" shape="round" block onClick={handleContinue}>
                                Continue
                            </AdmirianButton>
                            <ExploreInformationText>
                                You’re few steps away to <br /> explore
                            </ExploreInformationText>
                        </CarouselBox>
                        <CarouselBox >
                            <BasicInformationHeading>
                                <div>
                                    <ArrowLeftOutlined style={{ fontSize: 18 }} onClick={() => carouselRef.current.prev()} />
                                </div>
                                <div style={{ flexGrow: 3, textAlign: "center" }}>Give us your basic info</div>
                            </BasicInformationHeading>
                            {refresh_token?.user_type === "Brand" ? (
                                <>
                                    <Form.Item name="Website" label="Website" className="w-100">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="phone" label="Phone Number" className="w-100">
                                        <Input addonBefore="+91" placeholder="10 digits [0-9] phone number" onInput={isNumber} />
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <Form.Item name="gender" className="w-100" label="Select your gender">
                                        <Radio.Group>
                                            <Radio value="male">Male</Radio>
                                            <Radio value="female">Female</Radio>
                                            <Radio value="other">Other</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item className="w-100" name="dob" label="Date of Birth">
                                        <DatePicker
                                            onChange={(date, dateString) => {
                                                setDate(dateString);
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                    <Form.Item className="w-100" name="country" label="Country">
                                        <Select placeholder="Select Country" onChange={(value) => setLocation(value)} value={location}>
                                            <Option value="IN">India</Option>
                                            <Option value="AF">Afghanistan</Option>
                                            <Option value="AX">Aland Islands</Option>
                                            <Option value="AL">Albania</Option>
                                            <Option value="DZ">Algeria</Option>
                                            <Option value="AS">American Samoa</Option>
                                            <Option value="AD">Andorra</Option>
                                            <Option value="AO">Angola</Option>
                                            <Option value="AI">Anguilla</Option>
                                            <Option value="AQ">Antarctica</Option>
                                            <Option value="AG">Antigua and Barbuda</Option>
                                            <Option value="AR">Argentina</Option>
                                            <Option value="AM">Armenia</Option>
                                            <Option value="AW">Aruba</Option>
                                            <Option value="AU">Australia</Option>
                                            <Option value="AT">Austria</Option>
                                            <Option value="AZ">Azerbaijan</Option>
                                            <Option value="BS">Bahamas</Option>
                                            <Option value="BH">Bahrain</Option>
                                            <Option value="BD">Bangladesh</Option>
                                            <Option value="BB">Barbados</Option>
                                            <Option value="BY">Belarus</Option>
                                            <Option value="BE">Belgium</Option>
                                            <Option value="BZ">Belize</Option>
                                            <Option value="BJ">Benin</Option>
                                            <Option value="BM">Bermuda</Option>
                                            <Option value="BT">Bhutan</Option>
                                            <Option value="BO">Bolivia</Option>
                                            <Option value="BQ">Bonaire, Sint Eustatius and Saba</Option>
                                            <Option value="BA">Bosnia and Herzegovina</Option>
                                            <Option value="BW">Botswana</Option>
                                            <Option value="BV">Bouvet Island</Option>
                                            <Option value="BR">Brazil</Option>
                                            <Option value="IO">British Indian Ocean Territory</Option>
                                            <Option value="BN">Brunei Darussalam</Option>
                                            <Option value="BG">Bulgaria</Option>
                                            <Option value="BF">Burkina Faso</Option>
                                            <Option value="BI">Burundi</Option>
                                            <Option value="KH">Cambodia</Option>
                                            <Option value="CM">Cameroon</Option>
                                            <Option value="CA">Canada</Option>
                                            <Option value="CV">Cape Verde</Option>
                                            <Option value="KY">Cayman Islands</Option>
                                            <Option value="CF">Central African Republic</Option>
                                            <Option value="TD">Chad</Option>
                                            <Option value="CL">Chile</Option>
                                            <Option value="CN">China</Option>
                                            <Option value="CX">Christmas Island</Option>
                                            <Option value="CC">Cocos (Keeling) Islands</Option>
                                            <Option value="CO">Colombia</Option>
                                            <Option value="KM">Comoros</Option>
                                            <Option value="CG">Congo</Option>
                                            <Option value="CD">Congo, Democratic Republic of the Congo</Option>
                                            <Option value="CK">Cook Islands</Option>
                                            <Option value="CR">Costa Rica</Option>
                                            <Option value="CI">Cote D'Ivoire</Option>
                                            <Option value="HR">Croatia</Option>
                                            <Option value="CU">Cuba</Option>
                                            <Option value="CW">Curacao</Option>
                                            <Option value="CY">Cyprus</Option>
                                            <Option value="CZ">Czech Republic</Option>
                                            <Option value="DK">Denmark</Option>
                                            <Option value="DJ">Djibouti</Option>
                                            <Option value="DM">Dominica</Option>
                                            <Option value="DO">Dominican Republic</Option>
                                            <Option value="EC">Ecuador</Option>
                                            <Option value="EG">Egypt</Option>
                                            <Option value="SV">El Salvador</Option>
                                            <Option value="GQ">Equatorial Guinea</Option>
                                            <Option value="ER">Eritrea</Option>
                                            <Option value="EE">Estonia</Option>
                                            <Option value="ET">Ethiopia</Option>
                                            <Option value="FK">Falkland Islands (Malvinas)</Option>
                                            <Option value="FO">Faroe Islands</Option>
                                            <Option value="FJ">Fiji</Option>
                                            <Option value="FI">Finland</Option>
                                            <Option value="FR">France</Option>
                                            <Option value="GF">French Guiana</Option>
                                            <Option value="PF">French Polynesia</Option>
                                            <Option value="TF">French Southern Territories</Option>
                                            <Option value="GA">Gabon</Option>
                                            <Option value="GM">Gambia</Option>
                                            <Option value="GE">Georgia</Option>
                                            <Option value="DE">Germany</Option>
                                            <Option value="GH">Ghana</Option>
                                            <Option value="GI">Gibraltar</Option>
                                            <Option value="GR">Greece</Option>
                                            <Option value="GL">Greenland</Option>
                                            <Option value="GD">Grenada</Option>
                                            <Option value="GP">Guadeloupe</Option>
                                            <Option value="GU">Guam</Option>
                                            <Option value="GT">Guatemala</Option>
                                            <Option value="GG">Guernsey</Option>
                                            <Option value="GN">Guinea</Option>
                                            <Option value="GW">Guinea-Bissau</Option>
                                            <Option value="GY">Guyana</Option>
                                            <Option value="HT">Haiti</Option>
                                            <Option value="HM">Heard Island and Mcdonald Islands</Option>
                                            <Option value="VA">Holy See (Vatican City State)</Option>
                                            <Option value="HN">Honduras</Option>
                                            <Option value="HK">Hong Kong</Option>
                                            <Option value="HU">Hungary</Option>
                                            <Option value="IS">Iceland</Option>
                                            <Option value="ID">Indonesia</Option>
                                            <Option value="IR">Iran, Islamic Republic of</Option>
                                            <Option value="IQ">Iraq</Option>
                                            <Option value="IE">Ireland</Option>
                                            <Option value="IM">Isle of Man</Option>
                                            <Option value="IL">Israel</Option>
                                            <Option value="IT">Italy</Option>
                                            <Option value="JM">Jamaica</Option>
                                            <Option value="JP">Japan</Option>
                                            <Option value="JE">Jersey</Option>
                                            <Option value="JO">Jordan</Option>
                                            <Option value="KZ">Kazakhstan</Option>
                                            <Option value="KE">Kenya</Option>
                                            <Option value="KI">Kiribati</Option>
                                            <Option value="KP">Korea, Democratic People's Republic of</Option>
                                            <Option value="KR">Korea, Republic of</Option>
                                            <Option value="XK">Kosovo</Option>
                                            <Option value="KW">Kuwait</Option>
                                            <Option value="KG">Kyrgyzstan</Option>
                                            <Option value="LA">Lao People's Democratic Republic</Option>
                                            <Option value="LV">Latvia</Option>
                                            <Option value="LB">Lebanon</Option>
                                            <Option value="LS">Lesotho</Option>
                                            <Option value="LR">Liberia</Option>
                                            <Option value="LY">Libyan Arab Jamahiriya</Option>
                                            <Option value="LI">Liechtenstein</Option>
                                            <Option value="LT">Lithuania</Option>
                                            <Option value="LU">Luxembourg</Option>
                                            <Option value="MO">Macao</Option>
                                            <Option value="MK">Macedonia, the Former Yugoslav Republic of</Option>
                                            <Option value="MG">Madagascar</Option>
                                            <Option value="MW">Malawi</Option>
                                            <Option value="MY">Malaysia</Option>
                                            <Option value="MV">Maldives</Option>
                                            <Option value="ML">Mali</Option>
                                            <Option value="MT">Malta</Option>
                                            <Option value="MH">Marshall Islands</Option>
                                            <Option value="MQ">Martinique</Option>
                                            <Option value="MR">Mauritania</Option>
                                            <Option value="MU">Mauritius</Option>
                                            <Option value="YT">Mayotte</Option>
                                            <Option value="MX">Mexico</Option>
                                            <Option value="FM">Micronesia, Federated States of</Option>
                                            <Option value="MD">Moldova, Republic of</Option>
                                            <Option value="MC">Monaco</Option>
                                            <Option value="MN">Mongolia</Option>
                                            <Option value="ME">Montenegro</Option>
                                            <Option value="MS">Montserrat</Option>
                                            <Option value="MA">Morocco</Option>
                                            <Option value="MZ">Mozambique</Option>
                                            <Option value="MM">Myanmar</Option>
                                            <Option value="NA">Namibia</Option>
                                            <Option value="NR">Nauru</Option>
                                            <Option value="NP">Nepal</Option>
                                            <Option value="NL">Netherlands</Option>
                                            <Option value="AN">Netherlands Antilles</Option>
                                            <Option value="NC">New Caledonia</Option>
                                            <Option value="NZ">New Zealand</Option>
                                            <Option value="NI">Nicaragua</Option>
                                            <Option value="NE">Niger</Option>
                                            <Option value="NG">Nigeria</Option>
                                            <Option value="NU">Niue</Option>
                                            <Option value="NF">Norfolk Island</Option>
                                            <Option value="MP">Northern Mariana Islands</Option>
                                            <Option value="NO">Norway</Option>
                                            <Option value="OM">Oman</Option>
                                            <Option value="PK">Pakistan</Option>
                                            <Option value="PW">Palau</Option>
                                            <Option value="PS">Palestinian Territory, Occupied</Option>
                                            <Option value="PA">Panama</Option>
                                            <Option value="PG">Papua New Guinea</Option>
                                            <Option value="PY">Paraguay</Option>
                                            <Option value="PE">Peru</Option>
                                            <Option value="PH">Philippines</Option>
                                            <Option value="PN">Pitcairn</Option>
                                            <Option value="PL">Poland</Option>
                                            <Option value="PT">Portugal</Option>
                                            <Option value="PR">Puerto Rico</Option>
                                            <Option value="QA">Qatar</Option>
                                            <Option value="RE">Reunion</Option>
                                            <Option value="RO">Romania</Option>
                                            <Option value="RU">Russian Federation</Option>
                                            <Option value="RW">Rwanda</Option>
                                            <Option value="BL">Saint Barthelemy</Option>
                                            <Option value="SH">Saint Helena</Option>
                                            <Option value="KN">Saint Kitts and Nevis</Option>
                                            <Option value="LC">Saint Lucia</Option>
                                            <Option value="MF">Saint Martin</Option>
                                            <Option value="PM">Saint Pierre and Miquelon</Option>
                                            <Option value="VC">Saint Vincent and the Grenadines</Option>
                                            <Option value="WS">Samoa</Option>
                                            <Option value="SM">San Marino</Option>
                                            <Option value="ST">Sao Tome and Principe</Option>
                                            <Option value="SA">Saudi Arabia</Option>
                                            <Option value="SN">Senegal</Option>
                                            <Option value="RS">Serbia</Option>
                                            <Option value="CS">Serbia and Montenegro</Option>
                                            <Option value="SC">Seychelles</Option>
                                            <Option value="SL">Sierra Leone</Option>
                                            <Option value="SG">Singapore</Option>
                                            <Option value="SX">Sint Maarten</Option>
                                            <Option value="SK">Slovakia</Option>
                                            <Option value="SI">Slovenia</Option>
                                            <Option value="SB">Solomon Islands</Option>
                                            <Option value="SO">Somalia</Option>
                                            <Option value="ZA">South Africa</Option>
                                            <Option value="GS">South Georgia and the South Sandwich Islands</Option>
                                            <Option value="SS">South Sudan</Option>
                                            <Option value="ES">Spain</Option>
                                            <Option value="LK">Sri Lanka</Option>
                                            <Option value="SD">Sudan</Option>
                                            <Option value="SR">Suriname</Option>
                                            <Option value="SJ">Svalbard and Jan Mayen</Option>
                                            <Option value="SZ">Swaziland</Option>
                                            <Option value="SE">Sweden</Option>
                                            <Option value="CH">Switzerland</Option>
                                            <Option value="SY">Syrian Arab Republic</Option>
                                            <Option value="TW">Taiwan, Province of China</Option>
                                            <Option value="TJ">Tajikistan</Option>
                                            <Option value="TZ">Tanzania, United Republic of</Option>
                                            <Option value="TH">Thailand</Option>
                                            <Option value="TL">Timor-Leste</Option>
                                            <Option value="TG">Togo</Option>
                                            <Option value="TK">Tokelau</Option>
                                            <Option value="TO">Tonga</Option>
                                            <Option value="TT">Trinidad and Tobago</Option>
                                            <Option value="TN">Tunisia</Option>
                                            <Option value="TR">Turkey</Option>
                                            <Option value="TM">Turkmenistan</Option>
                                            <Option value="TC">Turks and Caicos Islands</Option>
                                            <Option value="TV">Tuvalu</Option>
                                            <Option value="UG">Uganda</Option>
                                            <Option value="UA">Ukraine</Option>
                                            <Option value="AE">United Arab Emirates</Option>
                                            <Option value="GB">United Kingdom</Option>
                                            <Option value="US">United States</Option>
                                            <Option value="UM">United States Minor Outlying Islands</Option>
                                            <Option value="UY">Uruguay</Option>
                                            <Option value="UZ">Uzbekistan</Option>
                                            <Option value="VU">Vanuatu</Option>
                                            <Option value="VE">Venezuela</Option>
                                            <Option value="VN">Viet Nam</Option>
                                            <Option value="VG">Virgin Islands, British</Option>
                                            <Option value="VI">Virgin Islands, U.s.</Option>
                                            <Option value="WF">Wallis and Futuna</Option>
                                            <Option value="EH">Western Sahara</Option>
                                            <Option value="YE">Yemen</Option>
                                            <Option value="ZM">Zambia</Option>
                                            <Option value="ZW">Zimbabwe</Option>
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                            <AdmirianButton
                                block
                                size="large"
                                shape="round"
                                style={{ marginBottom: 24 }}
                                // htmlType="submit"
                                onClick={() => {
                                    handleContinueForm();
                                }}>
                                Continue
                            </AdmirianButton>
                            <a
                                onClick={() => {
                                    if (refresh_token?.user_type === "Brand") {
                                        navigate(`/dashboard`);
                                    } else {
                                        navigate(`/analytics`);
                                    }
                                }}>
                                <DoThisLater style={{ marginTop: 0 }}>I’ll do this later</DoThisLater>
                            </a>
                            <ExploreInformationText>
                                You’re few steps away to <br /> explore
                            </ExploreInformationText>
                        </CarouselBox>
                        <CarouselBox >
                            <BasicInformationHeading>
                                <div>
                                    <ArrowLeftOutlined style={{ fontSize: 18 }} onClick={() => { setCarouselActiveIndex(1); carouselRef.current.prev(); }} />
                                </div>
                                <div style={{ flexGrow: 3, textAlign: "center" }}>{refresh_token?.user_type === "Brand" ? "Select your brand category" : "Select your category"}</div>
                            </BasicInformationHeading>
                            <Form.Item
                                name="Category"
                                label="Select Category"
                                className="w-100"
                                rules={[
                                    {
                                        required: "true",
                                        message: "Please select at least one category"
                                    }
                                ]}>
                                <Select mode="multiple" placeholder="Select Category" maxTagCount="responsive" onChange={(value) => handleSelectChange(value)} value={categorySelected}>
                                    {categoryList.map(({ id, category }) => {
                                        return (
                                            <Option key={id} value={id}>
                                                {category}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Row style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                {categoryList.map(({ id, category }) => {
                                    return (
                                        <CustomButton
                                            isactive={"true"}
                                            //isactive={categorySelected.includes(id)}
                                            key={id}
                                            onClick={() => {
                                                categorySelected.includes(id) ? removeItemAll(categorySelected, id) : setCategorySelected([...categorySelected, id]);
                                            }}>
                                            {category}
                                        </CustomButton>
                                    );
                                })}
                            </Row>
                            <AdmirianButton
                                block
                                shape="round"
                                size="large"
                                htmlType="submit"
                            // onClick={()=>carouselRef.current.next()}
                            >
                                Save and Complete
                            </AdmirianButton>
                            <a
                                onClick={() => {
                                    if (refresh_token?.user_type === "Brand") {
                                        navigate(`/dashboard`);
                                    } else {
                                        navigate(`/analytics`);
                                    }
                                }}>
                                <DoThisLater>I’ll do this later</DoThisLater>
                            </a>
                            <ExploreInformationText>
                                You’re few steps away to <br /> explore
                            </ExploreInformationText>
                        </CarouselBox>
                        <CarouselBox className={carouselActiveIndex === 3 ? "slide active-slide" : "slide"}>
                            <img src="https://static.admirian.com/86a9a05a-bdec-4709-b090-b53b848e28b1_Success.gif" height={350} className="w-100" alt="success" style={{ objectFit: 'contain' }} />
                            <GetStartedText
                                style={{
                                    background: "linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    backgroundClip: "text"
                                }}>
                                Hurray! all set get started
                            </GetStartedText>
                            <ExploreInformationText>
                                You’re few steps away to <br /> explore
                            </ExploreInformationText>
                        </CarouselBox>
                    </Carousel>
                </Form>
            </CarouselContainer>
            <Footer />
        </div>
    );
};

export default SignInFlow;
